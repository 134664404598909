export const URL = {
  // dev

  // API_BASE_URL: "http://localhost:5200",
  // API_BASE_URL: "http://192.168.225.111:5200",

  API_BASE_URL: "https://api.rilindia.biz",
  // colne
  // API_BASE_URL: "https://cloneapi.rilindia.online",
  // API_BASE_URL: "https://tiny-tan-puffer-tie.cyclic.cloud",
  API_BASE_URL_2: "https://api.rilindia.biz",

  // apipublic

  getOneUserPerCountry: "/publicApi/getOneUserPerCountry",
  getOneUserPerCity: "/publicApi/getOneUserPerCity",
  createcategories: "/apihome/createcategories",
  getCategory: "/publicApi/getCategory",
  userSearchProduct: "/publicApi/userSearchProduct",
  getcartProduct: "/publicApi/getcartProduct",
  referCheck: "/publicApi/referCheck",
  getbrandforpro: "/publicApi/getbrandforpro",

  registerUser: "/publicApi/registerUser",
  otpVerifyuser: "/publicApi/otpVerifyuser",

  // user

  LedAchiversList: "/sellerApi/LedAchiversList",
  checkpincode: "/sellerApi/checkpincode",
  addItemCart: "/sellerApi/addItemCart",
  getcartitme: "/sellerApi/getcartitme",
  afterLoginAddCart: "/sellerApi/afterLoginAddCart",
  removeItemCartCount: "/sellerApi/removeItemCartCount",
  addItemCartCount: "/sellerApi/addItemCartCount",
  removeCartItem: "/sellerApi/removeCartItem",
  removeAllCartItem: "/sellerApi/removeAllCartItem",
  createAddress: "/sellerApi/createAddress",
  createOrder: "/sellerApi/createOrder",
  getUserAddress: "/sellerApi/getUserAddress",
  getuserOrder: "/sellerApi/getuserOrder",
  getuserdownselllist: "/sellerApi/getuserdownselllist",
  getuserOrderDetails: "/sellerApi/getuserOrderDetails",
  getuserdeshboardData: "/sellerApi/getuserdeshboardData",
  addlicenceUser: "/sellerApi/addlicenceUser",
  userallteamlistexportex: "/sellerApi/userallteamlistexportex",
  getuserBussnisslastthreeMonth2: "/sellerApi/getuserBussnisslastthreeMonth2",
  LeaderBoard: "/sellerApi/LeaderBoard",
  LeaderBoardbyAdmin: "/sellerApi/LeaderBoardbysell",

  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcatehome: "/publicApi/getsubsubcatehome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  productDetailsReleted: "/publicApi/productDetailsReleted",

  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",
  getsubsubCategoryList: "/publicApi/getsubsubCategoryList",
  getsubsubCategoryListfillforsub: "/publicApi/getsubsubCategoryListfillforsub",
  getsubsubCategoryListfill2: "/publicApi/getsubsubCategoryListfill2",
  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  gethomePro: "/publicApi/gethomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",
  getsellerProductListPublicebymaincategory2Subcate:
    "/publicApi/getsellerProductListPublicebymaincategory2Subcate",
  getsellerProductListPublicebymaincategory2newLaunch:
    "/publicApi/getsellerProductListPublicebymaincategory2newLaunch",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  getBussProfile1: "/sellerApi/getBussProfile1",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",
  getPackageList: "/sellerApi/getPackageList",
  createPurchase: "/sellerApi/createPurchase",
  getsellerTransaction: "/sellerApi/getsellerTransaction",
  getprofile: "/sellerApi/getprofile",
  genelogybyuser: "/sellerApi/genelogybyuser",
  getuserBussnissMonth: "/sellerApi/getuserBussnissMonth",
  userCommissionpreviousmonth: "/sellerApi/userCommissionpreviousmonth",
  updatesellerProduct: "/sellerApi/updatesellerProduct",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",
  getmessageSupportList: "/sellerApi/getmessageSupportList",
  createMessage: "/sellerApi/createMessage",
  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/sellerApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",
  getExpireDate: "/sellerApi/getExpireDate",
  getsellerprofilebyseller: "/sellerApi/getsellerprofilebyseller",
  sellerDateWiseReport: "/sellerApi/sellerDateWiseReport",
  sellerDirectTeam: "/sellerApi/sellerDirectTeam",
  sellerDirectTeamfilter: "/sellerApi/sellerDirectTeamfilter",
  checkstocknow: "/sellerApi/checkstocknow",
  userMonthIncome: "/sellerApi/userMonthIncome",
  userstatement: "/sellerApi/userstatement",
  createChelenge: "/sellerApi/createChelenge",
  userallteamlist: "/sellerApi/userallteamlist",
  userallteamlistfilter: "/sellerApi/userallteamlistfilter",
  weekWiseSale: "/sellerApi/weekWiseSale",
  dayWiseSale: "/sellerApi/dayWiseSale",
  myTeamCount: "/sellerApi/myTeamCount",
  getChelenge: "/sellerApi/getChelenge",
  edituserProfileByuser: "/sellerApi/edituserProfileByuser",
  edituserKycByUser: "/sellerApi/edituserKycByUser",
  edituserProfileimageByUser: "/sellerApi/edituserProfileimageByUser",

  getsliderList: "/publicApi/getsliderList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  getInvoceDetails: "/publicApi/getInvoceDetails",
  createContactqueries: "/publicApi/createContactqueries",
};
