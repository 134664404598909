import React from "react";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";

function PrivecyPolicy() {
  return (
    <div>
      <HeaderMain />
      <div className="container">
        <h1>Privacy Policy</h1>
        <p>
          Resolved Improve Life India Marketing (OPC) Pvt Ltd. (the “Company”)
          would like to thank you for visiting our Platform rilindia.biz and
          official site, if any (hereinafter referred to as “Platforms").
        </p>
        <p>
          This policy is prepared and published by virtue of Section 43 A of the
          Information Technology Act, 2000; Regulation 4 of the Information
          Technology (Reasonable Security Practices and Procedures and Sensitive
          Personal Information) Rules, 2011 (“SPI Rules”); and Regulation 3(1)
          of the Information Technology (Intermediaries Guidelines) Rules, 2011.
        </p>
        <p>
          Person visiting our Platform shall be referred to as “User/You”
          throughout this policy.
        </p>
        <p>
          This Policy describes how we may collect or receive your personal
          data, the types of personal data we may collect, how we use, share and
          protect these data, how long we retain these data, your rights, and
          how you can contact us about our privacy practices.
        </p>
        <p>
          Please note, by visiting the Platform you agree to be bound by the
          terms and conditions of this Privacy Policy. If you do not agree
          please do not use or access our Platform. By mere use of the Platform,
          you expressly consent to our use and disclosure of your personal
          information in accordance with this Privacy Policy. This Privacy
          Policy is incorporated into and subject to the Terms of Use.
        </p>
        <p>
          The Company reserves its right to change the Policy at any time
          without notice. To make sure you are aware of any changes, please
          review this policy periodically.
        </p>

        <h2>1. Collection of Information</h2>
        <h3>
          I. Individual’s Personal Information (“IPI”), shall mean information
          capable of identifying the User and includes
        </h3>
        <ul>
          <li>Your User name along with Your password,</li>
          <li>
            Name, Address, Telephone Number, email address and other contact
            details
          </li>
          <li>User’s date of birth and gender,</li>
          <li>
            Information regarding User’s transactions on the Platform,
            (including sales or purchase history),
          </li>
          <li>
            User’s financial information such as bank account information or
            credit card or debit card or other payment instrument details.
          </li>
        </ul>

        <h3>II. Information related to individual’s Bank</h3>
        <ul>
          <li>Your Account detail that has been shared with us by you</li>
          <li>
            Your account details that has been recorded by from on the
            information given by you.
          </li>
          <li>
            Details about the bank and the credentials of the card shared by
            you.
          </li>
        </ul>

        <h3>III. Documentation information</h3>
        <ul>
          <li>
            All the information that is written In your documents and
            subsequently shared with the company through the KYC and the
            information about your documentation including aadhar card, pan card
            and any other other official document.
          </li>
        </ul>

        <h2>2. Share of your information</h2>
        <p>
          We may share your information with in the company as we demand and
          that can be used in any way as we deemed fit. Sharing of the data we
          stored as your person and the bank details will not be shared with the
          third party that is not affiliated with our company.
        </p>
        <p>
          We may share the foresaid information with government authorities and
          agencies for the purposes of verification of identity or for
          prevention, detection or investigation, including of cyber incidents,
          prosecution and punishment of offences. You agree and consent for our
          Platforms to disclose your information, if so required, under
          applicable law.
        </p>
        <p>
          The Company may also disclose or transfer the User Information, to any
          third party as a part of reorganization or a sale of the assets,
          division or transfer of a part or whole of the company. Any third
          party to which the Company transfers or sells its assets will have the
          right to continue to use the User Information that Users provide to
          us.
        </p>

        <h2>3. Retention and Security of User Information</h2>
        <ul>
          <li>
            The security of User’s Information is important to us. The Company
            shall keep your data safe and provide security against any
            unauthorized access or unauthorized alteration, disclosure or
            destruction.
          </li>
          <li>
            The Company shall not be liable for any loss or injury caused to the
            User as a result of User Information shared by the User directly to
            any third party (including any third party Platforms, even if links
            to such third party Platforms are provided on the Platform) for and
            on behalf of the Company.
          </li>
          <li>
            While the Company will endeavour to take all reasonable and
            appropriate steps to keep secure any User Information which it holds
            about the User and prevent unauthorized access, the User acknowledge
            that the internet or computer networks are not fully secure and that
            the Company cannot provide any absolute assurance regarding the
            security of User Information.
          </li>
        </ul>

        <h2>4. Consent to Use of Platforms</h2>
        <ul>
          <li>
            By using the Platforms or by providing User Information, User
            consents to the collection and use of the information he /she
            discloses on the Platforms in accordance with this Privacy Policy.
          </li>
          <li>
            The Company reserve its right to change this policy or part thereof,
            and such changes will be published on this page so that Users are
            always aware about updated version of our Privacy Policy.
          </li>
          <li>
            If a User wishes to remove his/her contact information from our
            Platforms and newsletters, the User can click on the "unsubscribe"
            link or follow the instructions in each e-mail message.
          </li>
          <li>
            The Company reserves its right to limit membership based on
            availability of contact information.
          </li>
        </ul>

        <h2>5. Jurisdiction</h2>
        <p>
          Any dispute or difference arise in relation to interpretation of the
          provisions of this Privacy Policy shall be governed by the provisions
          of Indian laws and Delhi court shall have the exclusive jurisdiction
          to entertain the same.
        </p>
      </div>
      <FooterMain />
    </div>
  );
}

export default PrivecyPolicy;
