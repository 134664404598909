import React from "react";
import AboutCom from "../HomeComponent/AboutCom";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";
// import AboutCom from "../../component/HomeComponent/AboutCom";

function About() {
  return (
    <div>
      <HeaderMain />
      <div className="abpage">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12 pic img-fluid p-0">
              {/* <!-- <img src="https://png.pngtree.com/thumb_back/fh260/background/20220428/pngtree-modern-office-interior-with-furniture-image_1107585.jpg" height="40px" class="img-fluid"> --> */}
            </div>
          </div>
        </div>
        <div class="container first1">
          <div class="row">
            <div class="col-12">
              <p class="p-3 text-center our">
                Just like every story begins with a dream, passion & mission,
                RILINDIA came into life with the same!
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row mt-4">
            <div class="col-sm-12 text-center p-0">
              <p class="per">
                RIL INDIA is direct selling private limited company which aims
                is to sell products at affordable prices and to reach the
                globally, we sell our product with our own brand ZIG-ZAGGER and
                RODWAY If you would like to RIL INDIA is the ultimate
                destination for fashion and lifestyle, being host to a wide
                array of merchandise including clothing, accessories and more.
                It is time to redefine your style statement with our
                treasure-trove of trendy items. It empowers unemployed person to
                reach million of customers with a number of workshop to boost
                their earning, life style and communication skills.
              </p>
              <p class="per">
                RIL India is a Indian company which is working on "Make In
                India-Made In India" for creating business opportunities for the
                youth and making them economically & financially independent
              </p>
             
            </div>
          </div>
          <br />
          <br />

          <br />
          {/* <div class="row">
          <div class="col-sm-12 text-center p-0">
            <p class="per-1">RIL INDIA JOURNEY</p>
          </div>
        </div>
        <br />
        <br />

        <div class="row">
          <div class="col-sm-12 p-0">
            <center>
            
            </center>
          </div>
        </div> */}

          {/* <br />
        <br />
        <br />
        <div class="row">
          <div class="col-sm-12 text-center p-0">
            <p class="per-1">THE 4 PILLARS OF </p>
          </div>
        </div>

        <br />
        <br />

        <br />
        <div class="row">
          <div class="col-md-3 p-0">
            <center> 
              <br />
              <br />
              <br />
              <br />
              <br />
              <p class="per-1">People</p>
            </center>
          </div>

          <div class="col-md-3 p-0">
            <center>
             
              <br />
              <br />
              <br />
              <br />
              <p class="per-1 mt-2">Passion</p>
            </center>
          </div>

          <div class="col-md-3 p-0">
            <center>
             
              <br />
              <br />
              <br />
              <br />
              <p class="per-1 mt-2">Potential</p>
            </center>
          </div>

          <div class="col-md-3 p-0">
            <center>
             
              <br />
              <br />
              <br />
              <br />
              <p class="per-1">Prosperity</p>
            </center>
          </div>
        </div> */}
        </div>

        <div class="container-fluid got-3">
          <h1 class="per-1 text-center mt-5 pt-4">OUR VALUES</h1>

          <AboutCom />
          {/* <div class="row mt-5 p-4">
          <div class="col-lg-6 pt-3">
            <div class="card mb-3 box">
              <img
                style={{ height: "200px" }}
                src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTpRn7ccPd5wUKHnBWFBaycqR2SpjjIp77IUFsR0w3d6jLHnc11"
                class="card-img-top pic"
                alt="..."
              />
              <div
                class="card-body"
                style={{ borderBottom: "3px solid dodgerblue" }}
              >
                <h4 class="card-title text-center">Integrity</h4>
                <br />
                <p class="card-text text-center">
                  Our integrity in our work methodology has helped us create a
                  strong system where growth & prosperity of each member of the
                  community is valued more than anything else.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </div>
      <FooterMain />    
    </div>
  );
}

export default About;
