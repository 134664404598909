// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";

function Brand() {
  const dispatch = useDispatch();
  const get_all_brand = useSelector((state) =>
    state?.allapi?.get_all_brand ? state?.allapi?.get_all_brand : []
  );

  console.log(get_all_brand);

  useEffect(() => {
    dispatch(allapiAction.getbrandforpro({}));
    return () => {};
  }, []);

  return (
    <div>
      <HeaderMain />
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <img
              src="https://us.benetton.com/on/demandware.static/-/Library-Sites-BenettonSharedLibrary/default/dw3a5a997c/01-REDESIGN/LANDER/WWW-lander-landing-hp.jpg"
              class="img-fluid"
              height="400"
            />
          </div>
        </div>
      </div>
      <div class="container-fluid bg-light">
        <div class="row">
          <div class="col-sm-12 text-center p-2">
            <h1>OUR BRANDS</h1>
          </div>
        </div>

        {get_all_brand &&
          get_all_brand?.map((data, i) => {
            return i % 2 == 0 ? (
              <div class="row">
                <div class="col-sm-6">
                  <img
                    src={URL?.API_BASE_URL + data?.image}
                    class="img-fluid"
                    height="360"
                  />
                </div>
                <div class="col-sm-6 text-center">
                  <img class="img-fluid" src={URL?.API_BASE_URL + data?.icon} />
                  <p>{data?.description}</p>
                </div>
              </div>
            ) : (
              <div class="row mt-5">
                <div class="col-sm-6 text-center">
                  <img class="img-fluid" src={URL?.API_BASE_URL + data?.icon} />
                  <p>{data?.description}</p>
                </div>
                <div class="col-sm-6">
                  <img
                    src={URL?.API_BASE_URL + data?.image}
                    class="img-fluid"
                    height="360"
                  />
                </div>
              </div>
            );
          })}

        {/* <div class="row mt-5">
          <div class="col-sm-6">
        
          </div>
          <div class="col-sm-6 text-center">
       
            <p>
              A classic man powerhouse with sexy, smooth yet truly masculine
              clothing range! Mr Huffman is a premium menswear brand known for
              its meticulously and elegantly crafted apparel to add an extra
              dimension to the men’s closet. Designed with the finest choices of
              textures to offer a comfortable and fresh feel, the brand is known
              for not compromising in terms of fabric quality and brief
              detailing. The elite selection is for the men with substances, so
              keep up with the times and overhaul your wardrobe with shrewd and
              in-vogue pieces of clothing.
            </p>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-sm-6 text-center">
          
            <p>
              Gone are the days when basic wear used to be predictable with old
              denim, loose tees, poorly-fitted trousers and boring shoes.
              Keeping it basic yet in style is what classy people dependably
              lookup for. Ifazone is a brand that provides a clean range of men
              and women clothing by playing with the finest fabrics & textures.
              The extra-friendly user interface features the most sophisticated
              edition of soothing colours, affordability and quality garment
              that ensures greatest solace and sturdiness with an ultimate
              objective of enhancing the personality of the wearer.
            </p>
          </div>
          <div class="col-sm-6">
         
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-sm-6">
          
          </div>
          <div class="col-sm-6 text-center">
           
            <p>
              Make your best better with the exclusive sportswear collection by
              Kuefit. The brand is known for adding a dash of sporty spirit and
              athletic enchant clothing range alongside the maximum comfort.
              Kuefit covers all your athletics choices shrouded in supreme style
              with the wide array of men and women sportswear. With finest of
              textures and styles, the brand is best for a high measure of sweat
              assimilation and provides the wearer with a fresh feel. Find your
              own fitness with Kuefit and get set to perform in style!
            </p>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-sm-6 text-center">
           
            <p>
              The right selection of footwear defines the personality of the
              wearer. Keeping that in mind, Solasta is here for spoiling you
              with the best quality and wide exhibit of formal shoes, casual
              shoes, canvas shoes, heels, flats, sports shoes and many more in
              most extreme style. From men’s formal shoes that explain its
              timeless appeal to women’s classic high heels that flaunts the
              strong, powerful yet feminine look, the shoe brand has so much to
              offer. Solasta is the brand that oozes solace, glamour and style.
            </p>
          </div>
          <div class="col-sm-6">
           
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-sm-6">
          
          </div>
          <div class="col-sm-6 text-center">
      
            <p>
              Not just another women clothing brand but a companion for your
              life. Amiiga is a friend you can hold on to your worse times and
              better. She listens to your giggles and to your silence. She has a
              solution to your every dilemma and teaches you the value of being
              you. She is a soulmate to your persona. The missing puzzle to your
              enigma. She is Amiiga!
            </p>
          </div>
        </div> */}
      </div>
      <FooterMain />
    </div>
  );
}

export default Brand;
