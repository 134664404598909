import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { URL } from "../../Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import ImageUploading from "react-images-uploading";
// import ReactImageMagnify from "react-image-magnify";
// import { useNavigate } from "react-router-dom";
// import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";

// import {
//   FacebookIcon,
//   FacebookShareButton,
//   TwitterIcon,
//   TwitterShareButton,
//   LinkedinShareButton,
//   LinkedinIcon,
// } from "react-share";
import { setUserName, setUserNumber } from "../../Utils/Auth/Token";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { setUserName, setUserNumber } from "@/Utils/Auth/Token";
// import Link from "next/link";
import BounceLoader from "react-spinners/BounceLoader";
// import Image from "next/image";

import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import TruncateText from "../TruncateText/TruncateText";

function Product1() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const all_sub_sub_categories_home_List = useSelector((state) =>
    state?.allapi?.all_sub_sub_categories_home_List
      ? state?.allapi?.all_sub_sub_categories_home_List
      : {}
  );
  const get_home_all_pro = useSelector((state) =>
    state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  );

  const loading = useSelector((state) =>
    state?.allapi?.loading ? state?.allapi?.loading : false
  );
  const loading1 = useSelector((state) =>
    state?.allapi?.loading1 ? state?.allapi?.loading1 : false
  );

  const [showView, setShowView] = useState(false);
  // const handleCloseDelete = () => setShowDelete(false);
  const handleCloseView = () => setShowView(false);
  const [dispImg, setDispImg] = useState("/assets/imgs/shop/product-16-2.jpg");
  const [productImage, setproductImage] = useState([]);
  const [productData, setproductData] = useState({});
  const [activeCate, setactiveCate] = useState("");

  const get_product_details = useSelector((state) =>
    state?.allapi?.get_product_details ? state?.allapi?.get_product_details : {}
  );

  useEffect(() => {
    const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

    const allImg = get_product_details?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    if (get_product_details?.images !== undefined) {
      const allImgs = allImg?.filter((data) => {
        return !data.match("undefined");
      });

      const allimgMarge = [feImg, ...allImgs];
      setDispImg(feImg);
      setproductImage(allimgMarge);
    }
    setproductData(get_product_details);
  }, [get_product_details]);

  const hendleModalShowView = (e) => {
    setShowView(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    dispatch(allapiAction.productDetails(e?.pro_sulg));
    // dispatch(allapiAction.getsubsubcatehome({}));

    // const allimgMarge = [feImg, ];
    // setDispImg(feImg);
    // setproductImage(allimgMarge);

    // setproductData(e);
    return () => {};
  };

  const [showWhatsappIn, setShowWhatsappIn] = useState(false);
  const handleCloseWhatsappIn = () => setShowWhatsappIn(false);
  const handleOpneWhatsappIn = () => setShowWhatsappIn(true);
  const [showEmailIn, setShowEmailIn] = useState(false);
  const handleCloseEmailIn = () => setShowEmailIn(false);
  const handleOpneEmailIn = () => setShowEmailIn(true);

  const [whatInq, setwhatInq] = useState({ countryCode: "", whatsappNo: "" });
  const [emailInq, setemailInq] = useState({
    country: "",
    customerEmail: "",
    userName: "",
  });

  useEffect(() => {
    dispatch(allapiAction.gethomePro({}));
  }, []);

  const hendleModalEmailView = (e) => {
    setShowEmailIn(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    const allImgs = allImg?.filter((data) => {
      return !data.match("undefined");
    });

    const allimgMarge = [feImg];
    setDispImg(feImg);
    setproductImage(allimgMarge);

    setproductData(e);
  };

  const hendleModalWhatsappView = (e) => {
    setShowWhatsappIn(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    const allImgs = allImg?.filter((data) => {
      return !data.match("undefined");
    });

    const allimgMarge = [feImg];
    setDispImg(feImg);
    setproductImage(allimgMarge);

    setproductData(e);
  };

  const hendleToWhatsapp = (e) => {
    const { name, value } = e.target;
    setwhatInq({ ...whatInq, [name]: value });
  };
  const hendleToEmail = (e) => {
    const { name, value } = e.target;
    setemailInq({ ...emailInq, [name]: value });
  };

  // start

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode1, setCountryCode1] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");

  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [countryCode12, setCountryCode12] = useState("");
  const [phoneNumber12, setPhoneNumber12] = useState("");
  const handlePhoneChange = (value) => {
    const phone = value || "";
    const phoneNumberObj = parsePhoneNumberFromString(phone);
    const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
    const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
    setCountryCode1(conCode);
    setPhoneNumber1(contry);
    setPhoneNumber(value);
  };

  const handlePhoneChange2 = (value) => {
    const phone = value || "";
    const phoneNumberObj = parsePhoneNumberFromString(phone);
    const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
    const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
    setCountryCode12(conCode);
    setPhoneNumber12(contry);
    setPhoneNumber2(value);
  };

  const hendleWhatsappIn = () => {
    // whatsappinquiresubmit

    if (!countryCode1) {
      toast.error("Please enter country code");
    } else if (!phoneNumber1) {
      toast.error("Please enter Number");
    } else if (!whatInq?.name) {
      toast.error("Please enter name");
    } else {
      dispatch(
        allapiAction.whatsappinquiresubmit({
          countryCode: countryCode1,
          whatsappNo: phoneNumber1,
          name: whatInq?.name,
          email: whatInq?.email,
          message: whatInq?.message,
          productName: productData?.title,
          productId: productData?._id,
          sellerId: productData?.sellerId?._id,
          sellerName: productData?.sellerId?.fullName,
          sellercountryCode: productData?.sellerId?.countryCode,
          sellerwhatsappNo: productData?.sellerId?.whatsappNumber,
        })
      );
      const userEmailData = JSON.stringify({
        countryCode: countryCode1,
        whatsappNo: phoneNumber1,
        name: whatInq?.name,
        email: whatInq?.email,
      });

      // whatsappNo: whatInq?.whatsappNo,
      setUserNumber(userEmailData);
      // dispatch(allapiAction.getsubsubcatehome({}));
      setShowWhatsappIn(false);
      setShowView(false);

      return () => {};
    }
  };

  // end
  const arraytest = [
    { name: "lokesh", age: "26" },
    { name: "lokesh1", age: "23" },
    { name: "lokesh2", age: "25" },
    { name: "lokesh2", age: "25" },
    { name: "lokesh", age: "26" },
  ];

  let uniqueArray = [];
  for (let i = 0; i < arraytest.length; i++) {
    let current = arraytest[i];
    if (!uniqueArray.some((x) => x.name === current.name)) {
      // assuming you are comparing property
      uniqueArray.push(current);
    }
  }

  // start

  const hendleEmailIn = () => {
    // whatsappinquiresubmit

    if (!emailInq?.userName) {
      toast.error("Please enter name");
    } else if (!emailInq?.customerEmail) {
      toast.error("Please enter email");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailInq?.customerEmail)
    ) {
      toast.error("invalid email");
    } else if (!phoneNumber12) {
      toast.error("Please enter number");
    } else {
      dispatch(
        allapiAction.emailenquirysubmit({
          // country: "", customerEmail: "",userName:""
          countryCode: countryCode12,
          whatsappNo: phoneNumber12,
          message: emailInq?.message,
          customerEmail: emailInq?.customerEmail,
          userName: emailInq?.userName,
          productName: productData?.title,
          productId: productData?._id,
          sellerId: productData?.sellerId?._id,
          sellerName: productData?.sellerId?.fullName,
          sellerEmail: productData?.sellerId?.email,
          sellerwhatsappNo: productData?.sellerId?.whatsappNumber,
        })
      );
      const userEmailData = JSON.stringify({
        countryCode: countryCode12,
        whatsappNo: phoneNumber12,
        Name: emailInq?.userName,
        Email: emailInq?.customerEmail,
        // message: emailInq?.message,
      });

      setUserName(userEmailData);
      // dispatch(allapiAction.getsubsubcatehome({}));
      setShowEmailIn(false);
      setShowView(false);

      return () => {};
    }
  };

  useEffect(() => {
    const blogIds = localStorage.getItem("access_name");
    const blogIdss = blogIds !== null ? blogIds : "{}";
    const arr = JSON.parse(blogIdss);

    const blogIdsnum = localStorage.getItem("access_number");
    const blogIdssnum = blogIdsnum !== null ? blogIdsnum : "{}";
    const arrnum = JSON.parse(blogIdssnum);

    setPhoneNumber("+" + arrnum?.countryCode + arrnum?.whatsappNo);
    setPhoneNumber2("+" + arr?.countryCode + arr?.whatsappNo);

    setCountryCode1(arrnum?.countryCode);
    setPhoneNumber1(arrnum?.whatsappNo);
    setCountryCode12(arr?.countryCode);
    setPhoneNumber12(arr?.whatsappNo);

    setwhatInq({
      name: arrnum?.name,
      email: arrnum?.email,
    });
    setemailInq({
      // message: arr?.message,
      customerEmail: arr?.Email,
      userName: arr?.Name,
    });
  }, []);

  // end

  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );

  const hendleToChange = (e, id) => {
    setactiveCate(e);
    dispatch(allapiAction.gethomePro({ cateId: id }));
  };
  const hendleToAddItme = (e, id) => {
    // setactiveCate(e);
    dispatch(allapiAction.AddCartItme(e));
  };

  useEffect(() => {
    // const blogIds = localStorage.getItem("access_name");
    // const blogIdss = blogIds !== null ? blogIds : "{}";
    // const arr = JSON.parse(blogIdss);
    // const blogIdsnum = localStorage.getItem("access_number");
    // const blogIdssnum = blogIdsnum !== null ? blogIdsnum : "{}";
    // const arrnum = JSON.parse(blogIdssnum);
    // setwhatInq({
    //   countryCode: arrnum?.countryCode,
    //   whatsappNo: arrnum?.whatsappNo,
    // });
    // setemailInq({
    //   country: arr?.country,
    //   customerEmail: arr?.Email,
    //   userName: arr?.Name,
    // });
  }, []);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the width and height based on screen width
  const imageWidth = windowWidth <= 767 ? 150 : 300;
  const imageHeight = windowWidth <= 767 ? 150 : 210;

  return (
    <div>
      <Modal show={showView} size="xl" onHide={handleCloseView}>
        <Modal.Header closeButton>
          <Modal.Title> Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <BounceLoader color="#ff0000" />
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <div className="productPage__left ">
                      <div className="productPage__sideImagesContainer">
                        {productImage &&
                          productImage?.map((item, index) => {
                            return (
                              <div
                                onClick={() => setDispImg(item)}
                                className="sideImage"
                                // style={{
                                //   backgroundImage: `url(${
                                //     URL.API_BASE_URL + item
                                //   })`,
                                // }}
                                key={index}
                              >
                                <Image
                                  src={item}
                                  alt={productData?.title + index}
                                  width={imageWidth}
                                  height={imageHeight}
                                  // onError={(e) => {
                                  //   e.target.onerror = null;
                                  //   e.target.src = "/assets/imgs/notfound.png";
                                  // }}
                                />
                              </div>
                            );
                          })}
                      </div>
                      <div className="productPage__displayImageContainer">
                        <div
                          className="productPage__displayImage"
                          // style={{
                          //   backgroundImage: `url(${
                          //     URL.API_BASE_URL + dispImg
                          //   })`,
                          // }}
                        >
                          <div style={{ width: "250px" }}>
                            <img
                              src={dispImg}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div>
                          {/* <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: true,
                                src: dispImg,
                                // sizes:
                                //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                              },
                              largeImage: {
                                src: dispImg,
                                width: 1200,
                                height: 1800,
                              },
                              // className: "imgViewS",
                              // imageStyle: {
                              //   maxHeight: "400px",
                              //   maxWidth: "100%",
                              //   width: "auto ",
                              //   margin: "auto",
                              // },
                              imageClassName: "imgViewS1",
                              // enlargedImageContainerDimensions: {
                              //   width: "200%",
                              //   height: "100%",
                              // },
                              enlargedImageStyle: {
                                width: "50px",
                              },
                              lensStyle: {
                                width: "20px",
                                height: "20px",
                              },
                              shouldUsePositiveSpaceLens: true,
                              shouldHideHintAfterFirstActivation: false,
                              // isHintEnabled: true,
                              lensStyle: {
                                backgroundColor: "rgba(0,0,0,.6)",
                              },
                              enlargedImagePosition: "over",
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 ">
                    <div className="detail-info pr-30 pl-30">
                      {/* <span className="stock-status out-stock"> Sale Off </span> */}
                      <h3 className="title-detail mb-30">
                        <a href="#" className="text-heading">
                          {productData?.title}
                        </a>
                      </h3>

                      {/* <div className="clearfix product-price-cover">
                        <div className="product-price primary-color float-left">
                          <span className="current-price text-brand">$38</span>
                          <span>
                            <span className="save-price font-md color3 ml-15">
                              26% Off
                            </span>
                            <span className="old-price font-md ml-15">$52</span>
                          </span>
                        </div>
                      </div> */}
                      <div className="detail-extralink mb-30">
                        {/* <div className="detail-qty border radius">
                          <a href="#" className="qty-down">
                            <i className="fi-rs-angle-small-down"></i>
                          </a>
                          <span className="qty-val">1</span>
                          <a href="#" className="qty-up">
                            <i className="fi-rs-angle-small-up"></i>
                          </a>
                        </div> */}
                        {/* <div className="product-extra-link2 mt-3">
                        <button
                          type="submit"
                          className="button button-add-to-cart"
                        >
                       
                          Email
                        </button>
                      </div>
                      <div className="product-extra-link2 mt-3 ms-2">
                        <button
                          type="submit"
                          className="button button-add-to-cart"
                        >
                       
                          Whatsapp
                        </button>
                      </div> */}
                      </div>
                      <div className="font-xs">
                        <ul>
                          <li className="mb-5">
                            Tag:{" "}
                            <span className="text-brand">
                              {productData?.tegs}, {productData?.tegs2}
                            </span>
                          </li>
                          <li className="mb-5">
                            Category:
                            <span className="text-brand">
                              {" "}
                              {productData?.categoryId?.name}
                            </span>
                          </li>
                          {/* <li className="mb-5">
                          Status:
                          <span className="text-brand">
                            {" "}
                            {productData?.status}
                          </span>
                        </li> */}
                          <li className="mb-5">
                            Description:
                            <div
                              className=" modalBoXContaint"
                              dangerouslySetInnerHTML={{
                                __html: productData?.description,
                              }}
                            ></div>
                            {/* <span className="text-brand">
                            {" "}
                            {productData?.description}
                          </span> */}
                          </li>
                        </ul>
                      </div>
                      <div className="product-extra-link2 ProBtn">
                        <Button variant="success" onClick={handleOpneEmailIn}>
                          Email
                        </Button>{" "}
                        {productData?.sellerId?.whatsappVerify && (
                          <Button
                            variant="success"
                            onClick={handleOpneWhatsappIn}
                          >
                            Whatsapp
                          </Button>
                        )}
                        {/* <button
                        type="submit"
                        className="button button-add-to-cart zindexne"
                      >
                        <i className="fi-rs-shopping-cart"></i>
                        Email
                      </button>
                      <button
                        type="submit"
                        className="button button-add-to-cart zindexne ms-3"
                      >
                        <i className="fi-rs-shopping-cart"></i>
                        Whatsapp
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseView}>
              No
            </Button>
            <Button variant="primary" onClick={hendleDelete}>
              Yes
            </Button>
          </Modal.Footer> */}
      </Modal>

      {/* whatsapp model  */}
      <Modal show={showWhatsappIn} onHide={handleCloseWhatsappIn}>
        <Modal.Header closeButton>
          <Modal.Title>Whatsapp Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              {/* <div className="mb-3">
                <label for="title" className="form-label">
                  Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Your Name"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Name
                </label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  value={whatInq?.name}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Email
                </label>
                <input
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  type="email"
                  value={whatInq?.email}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="teg" className="form-label">
                  Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country="IN"
                  defaultCountry="IN"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter message"
                  type="text"
                  value={whatInq?.message}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>
              {/* <div className="mb-3">
                <label for="teg" className="form-label">
                  Inquire
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter Inquire"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseWhatsappIn}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleWhatsappIn();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* email model  */}
      <Modal show={showEmailIn} onHide={handleCloseEmailIn}>
        <Modal.Header closeButton>
          <Modal.Title>Email Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              {/* <div className="mb-3">
                <label for="title" className="form-label">
                  Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Your Name"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Your Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Name"
                  type="text"
                  value={emailInq?.userName}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Email
                </label>
                <input
                  name="customerEmail"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  value={emailInq?.customerEmail}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country="IN"
                  defaultCountry="IN"
                  value={phoneNumber2}
                  onChange={handlePhoneChange2}
                />
              </div>
              <div className="mb-3">
                <label for="teg" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter Message"
                  type="text"
                  value={emailInq?.message}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmailIn}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleEmailIn();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="product-tabs section-padding position-relative">
        <div className="container">
          <div className="section-title style-2 wow animate__animated animate__fadeIn">
            <h3>Products</h3>
            <ul className="nav nav-tabs links" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={activeCate == "" ? "nav-link active" : "nav-link"}
                  id="nav-tab-one"
                  // data-bs-toggle="tab"
                  // data-bs-target="#tab-one"
                  type="button"
                  role="tab"
                  aria-controls="tab-one"
                  aria-selected="true"
                  onClick={() => {
                    hendleToChange("", "");
                  }}
                >
                  All
                </button>
              </li>

              {all_categories_List &&
                all_categories_List?.map((data, i) => {
                  return (
                    i < 6 && (
                      <li className="nav-item" role="presentation" key={i}>
                        <button
                          className={
                            activeCate == data?.name
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="nav-tab-two"
                          // data-bs-toggle="tab"
                          // data-bs-target="#tab-two"
                          type="button"
                          role="tab"
                          aria-controls="tab-two"
                          aria-selected="false"
                          onClick={() => {
                            hendleToChange(data?.name, data?._id);
                          }}
                        >
                          {data?.name}
                        </button>
                      </li>
                    )
                  );
                })}
            </ul>
          </div>
          {/*End nav-tabs*/}

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <BounceLoader color="#ff0000" />
            </div>
          ) : (
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tab-one"
                role="tabpanel"
                aria-labelledby="tab-one"
              >
                <div className="row product-grid-4">
                  {get_home_all_pro?.Product &&
                    get_home_all_pro?.Product?.map((data, i) => {
                      return (
                        <div
                          className="col-lg-1-5 col-md-4 col-6 col-sm-6"
                          key={i}
                        >
                          <div
                            className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                            data-wow-delay=".4s"
                          >
                            <div className="product-img-action-wrap">
                              <div className="product-img product-img-zoom">
                                <Link
                                  to={"/product-details/" + data?.pro_sulg}
                                  // href=""
                                  onClick={() => {
                                    // navigate(
                                    //   "/product-details/" + data?.pro_sulg
                                    // );
                                    // productclick
                                    // dispatch(
                                    //   allapiAction.productclick({
                                    //     productId: data?._id,
                                    //     categoryId: data?.categoryId?._id,
                                    //     sellerId: data?.sellerId?._id,
                                    //     type: "product",
                                    //   })
                                    // );
                                  }}
                                >
                                  <Image
                                    className="default-img"
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt={data?.title}
                                    width={imageWidth}
                                    height={imageHeight}
                                  />
                                </Link>
                              </div>
                              {/* <div className="product-action-1">
                                <a
                                  aria-label="Quick view"
                                  className="action-btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#quickViewModal"
                                  onClick={() => {
                                    hendleModalShowView(data);
                                  }}
                                >
                                  <i className="fi-rs-eye"></i>
                                </a>
                              </div> */}
                            </div>
                            <div className="product-content-wrap">
                              <div className="product-category">
                                <Link
                                  to={
                                    "/category-product/" +
                                    data?.categoryId?.cate_sulg
                                  }
                                  onClick={() => {
                                    // navigate(
                                    //   "/category-product/" +
                                    //     data?.categoryId?.cate_sulg
                                    // );
                                  }}
                                >
                                  {data?.categoryId?.name}
                                </Link>
                              </div>
                              <h2>
                                <Link
                                  to={"/product-details/" + data?.pro_sulg}
                                  onClick={() => {
                                    // navigate(
                                    //   "/product-details/" + data?.pro_sulg
                                    // );
                                    dispatch(
                                      allapiAction.productclick({
                                        productId: data?._id,
                                        categoryId: data?.categoryId,
                                        sellerId: data?.sellerId?._id,
                                        type: "product",
                                      })
                                    );
                                  }}
                                >
                                 <TruncateText
                                    text={data?.title}
                                    maxLength={windowWidth <= 767  ?17 :20}
                                  />
                                  {/* {data?.title} */}
                                </Link>
                              </h2>

                              <div className="product-card-bottom">
                                {/* {value && ( */}
                                  <div className="product-price">
                                    <span>₹{data?.afterdiscountprice}</span>
                                    {/* <span className="old-price">
                                      ₹{data?.price}
                                    </span> */}
                                  </div>
                                {/* )} */}
                                {/* <div className="add-cart">
                                  <a className="add"  onClick={(e) => {
                                      hendleToAddItme(data?._id);
                                    }}>
                                    <i className="fi-rs-shopping-cart mr-5"></i>Add{" "}
                                  </a>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/*End product-grid-4*/}
              </div>
            </div>
          )}
          {/*End tab-content*/}
        </div>
      </section>

     
    </div>
  );
}

export default Product1;
