// import { allapiAction } from "@/Redux/common/action";
// import { SHOW_MENU } from "@/Redux/common/constant";
// import { URL } from "@/Redux/common/url";
// import Head from "next/head";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { SHOW_MENU } from "../../Redux/common/constant";
import { URL } from "../../Redux/common/url";
// import { useNavigate } from "react-router-dom";
// import { allapiAction } from "../../../Redux/common/action";
// import { SHOW_MENU } from "../../../Redux/common/constant";
// import { URL } from "../../../Redux/common/url";

function HeaderAccount() {
  const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  const getsubsusbcategoriesAction = (data) => {
    return { type: SHOW_MENU, payload: data };
  };

  const menu_show = useSelector((state) =>
    state?.allapi?.menu_show ? state?.allapi?.menu_show : false
  );

  const get_buss_pro = useSelector((state) =>
    state?.allapi?.get_buss_pro ? state?.allapi?.get_buss_pro : {}
  );
  const get_buss_pro_1 = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );
  console.log(get_buss_pro_1);
  console.log(get_buss_pro);
  //   menu_show

  const hendleShowMenu = () => {
    const menuValue = menu_show == true ? false : true;
    dispatch(getsubsusbcategoriesAction(menuValue));
  };

  useEffect(() => {
    // dispatch(allapiAction.getsellerProductList(1));
    dispatch(allapiAction.getBussProfile({}));
    dispatch(allapiAction.getBussProfile1({}));
    return () => {};
  }, []);

  useEffect(() => {
    // dispatch(allapiAction.getPages({}));
    dispatch(allapiAction.footerPagepageListetPu({}));
    return () => {};
  }, []);

  const route = useNavigate();
  const logout = async () => {
    if (typeof window !== "undefined") {
      await localStorage.clear();
    }
    window.location.href = "/login";
    // route.push("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      // setTimeout(() => {
      // deshboard_state?.proCount
      route("/login");
      // }, 1000);
    }
  }, []);
  const [themetest, setthemetest] = useState("");
  // const [first, setfirst] = useState("")
  useEffect(() => {
    // localStorage.setItem("access_token_vendor", access_token);
    const token = localStorage.getItem("theme");
    setthemetest(token);
    return () => {};
  }, []);
  const hendletochngetheme = () => {
    if (themetest == "drak") {
      setthemetest("light");
      localStorage.setItem("theme", "light");
      dispatch(allapiAction.thememangment("light"));
    } else {
      setthemetest("drak");
      localStorage.setItem("theme", "drak");
      dispatch(allapiAction.thememangment("drak"));
    }
  };
  return (
    <div>
      {/* <Head>
        <title>{"ril"}</title>
       
        <meta name="viewport" content="width=device-width, initial-scale=1" />

   
      </Head> */}
      <header className="main-header navbar">
        <div className="col-search">
          {/* <form className="searchform">
            <div className="input-group">
              <input
                list="search_terms"
                type="text"
                className="form-control"
                placeholder="Search term"
              />
              <button className="btn btn-light bg" type="button">
                <i className="material-icons md-search"></i>
              </button>
            </div>
            <datalist id="search_terms">
              <option value="Products"></option>
              <option value="New orders"></option>
              <option value="Apple iphone"></option>
              <option value="Ahmed Hassan"></option>
            </datalist>
          </form> */}

          <div className="row verificationRed">
            <div className="col-2">{/* <Link href="/">home</Link> */}</div>
            <div className="col-10">
              {get_buss_pro_1?.status == "Inactive" && (
                <p>
                  Please wait for admin verification to complete access and
                  activation of your account
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-nav">
          <button
            className="btn btn-icon btn-mobile me-auto"
            data-trigger="#offcanvas_aside"
            onClick={() => {
              hendleShowMenu();
            }}
          >
            <i className="material-icons md-apps"></i>
          </button>
          <Link className={""} to="/" style={{ marginRight: "10px" }}>
            {/* Visit Home Page */}
            <i
              style={{ fontSize: "20px" }}
              class="fa fa-home"
              aria-hidden="true"
            ></i>
          </Link>
          <button
            className={themetest == "drak" ? "themebtn1" : "themebtn2"}
            onClick={() => {
              hendletochngetheme();
            }}
          >
            {" "}
            {themetest == "drak" ? (
              <span>
                {/* Drak Mode */}
                <i className="fa fa-moon-o"></i>
              </span>
            ) : (
              <span>
                {" "}
                <i className="fa fa-sun-o"></i>
                {/* Light Mode */}
              </span>
            )}
          </button>
          {/* <p>{get_buss_pro_1?.balance}</p> */}
          <ul className="nav">
            {/* <li className="nav-item">
              <a className="nav-link btn-icon" href="#">
                <i className="material-icons md-notifications animation-shake"></i>
                <span className="badge rounded-pill">3</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn-icon darkmode" href="#">
                {" "}
                <i className="material-icons md-nights_stay"></i>{" "}
              </a>
            </li>
            <li className="nav-item">
              <a href="#" className="requestfullscreen nav-link btn-icon">
                <i className="material-icons md-cast"></i>
              </a>
            </li> */}
            {/* <li className="dropdown nav-item">
              <a
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                id="dropdownLanguage"
                aria-expanded="false"
              >
                <i className="material-icons md-public"></i>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownLanguage"
              >
                <a className="dropdown-item text-brand" href="#">
                  <img src="/assets/imgs/theme/flag-us.png" alt="English" />
                  English
                </a>
                <a className="dropdown-item" href="#">
                  <img src="/assets/imgs/theme/flag-fr.png" alt="Français" />
                  Français
                </a>
                <a className="dropdown-item" href="#">
                  <img src="/assets/imgs/theme/flag-jp.png" alt="Français" />
                  日本語
                </a>
                <a className="dropdown-item" href="#">
                  <img src="/assets/imgs/theme/flag-cn.png" alt="Français" />
                  中国人
                </a>
              </div>
            </li> */}

            <li className="dropdown nav-item">
              <Dropdown>
                <Dropdown.Toggle className="dropButton" id="dropdown-basic">
                  <img
                    className="img-xs rounded-circle"
                    // src="/assets/imgs/people/avatar-2.png"
                    src={URL.API_BASE_URL + get_buss_pro_1?.profile_image}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/assets/imgs/people/avatar-2.png";
                    }}
                    alt=""
                  />
                </Dropdown.Toggle>

                {/* assets */}
                {/* 
                <img
 className="scale homeStudyCourses"
 src={URL.API_BASE_URL + get_buss_pro?.logo} onError={(e:any)=>{e.target.onerror = null; e.target.src="/assets/img/noImage.jpeg"}}
/> */}
                <Dropdown.Menu>
                  {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => {
                      logout();
                    }}
                  >
                    <i className="material-icons md-exit_to_app"></i>Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <a
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                id="dropdownAccount"
                aria-expanded="false"
              >
               
                <img
                  className="img-xs rounded-circle"
                  src="/assets/imgs/people/avatar-2.png"
                  alt="User"
                /> 
                Seller
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownAccount"
              >
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-perm_identity"></i>Edit Profile
                </a>
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-settings"></i>Account Settings
                </a>
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-account_balance_wallet"></i>Wallet
                </a>
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-receipt"></i>Billing
                </a>
                <a className="dropdown-item" href="#">
                  <i className="material-icons md-help_outline"></i>Help center
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item text-danger"
                  onClick={() => {
                    logout();
                  }}
                >
                  <i className="material-icons md-exit_to_app"></i>Logout
                </a>
              </div> */}
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default HeaderAccount;
