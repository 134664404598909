// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
// import dynamic from "next/dynamic";

// import Sidebar from "../Layout/Sidebar";
// import { ProSidebarProvider } from "react-pro-sidebar";
// import Header from "../Layout/Header";
// import AreaGrap from "../Graph/AreaGrap";
// import Footer from "../Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
// import AreaGrap from "../../../component/Graph/AreaGrap";
// import Footerr from "../../../component/sidebar/Footerr";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
import BounceLoader from "react-spinners/BounceLoader";
// import Api from "@/Redux/common/api";
import moment from "moment";
// import ProgressBar from "../../../component/Home/ProgressBar";
import Chart from "react-google-charts";
// import { URL } from "@/Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
import { URL } from "../../Redux/common/url";
import Confetti from "./Confetti";
import { useNavigate } from "react-router-dom";
// import { allapiAction } from "../../../Redux/common/action";

// AreaGrap

// import Cktest from "../../../component/cktetst/Cktest";
// const AreaGrap = dynamic(() => import("../../../component/Graph/AreaGrap"), {
//   ssr: false,
// });

function Dashboard() {
  const dispatch = useDispatch();

  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard_state ? state?.allapi?.deshboard_state : {}
  );

  console.log(deshboard);
  const team_count = useSelector((state) =>
    state?.allapi?.team_count ? state?.allapi?.team_count : {}
  );
  const top_three = useSelector((state) =>
    state?.allapi?.top_three ? state?.allapi?.top_three : {}
  );
  const get_leader_board_c_m = useSelector((state) =>
    state?.allapi?.get_leader_board_c_m
      ? state?.allapi?.get_leader_board_c_m
      : {}
  );

  console.log(
    "top_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_three",
    top_three
  );
  console.log(
    "top_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_three",
    deshboard?.team?.todaybuss
  );
  const week_data = useSelector((state) =>
    state?.allapi?.week_data ? state?.allapi?.week_data : []
  );
  const day_data = useSelector((state) =>
    state?.allapi?.day_data ? state?.allapi?.day_data : []
  );
  const get_last_three_month = useSelector((state) =>
    state?.allapi?.get_last_three_month
      ? state?.allapi?.get_last_three_month
      : []
  );
  const lead_achiver = useSelector((state) =>
    state?.allapi?.lead_achiver ? state?.allapi?.lead_achiver : []
  );
  // const loader = useSelector((state) =>
  //   state?.allapi?.loader ? state?.allapi?.loader : false
  // );

  const seller_update_details = useSelector((state) =>
    state?.allapi?.seller_update_details
      ? state?.allapi?.seller_update_details
      : {}
  );

  console.log(seller_update_details);

  console.log(get_last_three_month);

  const [loader, setfirst] = useState(true);

  const [lisshowcount, setlisscount] = useState("");
  const [managerKey, setManagerKey] = useState(0);
  // const [userselete, setuserselete] = useState(null);

  //   const route = useRouter();

  useEffect(() => {
    // if (!localStorage.getItem("access_token")) {

    //   route.push("/login");

    // }
    setTimeout(() => {
      setfirst(false);
    }, 1000);
  }, []);

  useEffect(() => {
    // sellerDashboardState
    dispatch(allapiAction.weekWiseSale({}));
    dispatch(allapiAction.dayWiseSale({}));
    dispatch(allapiAction.myTeamCount({}));
    dispatch(allapiAction.LeaderBoard({}));
    // dispatch(allapiAction.LeaderBoardbyAdmin({}));
    dispatch(allapiAction.LedAchiversList({}));
    dispatch(allapiAction.getuserBussnisslastthreeMonth2({}));

    dispatch(allapiAction.getsellerprofilebyseller({}));
    return () => {};
  }, []);

  const profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  const user_pre_month_commission = useSelector((state) =>
    state?.allapi?.user_pre_month_commission
      ? state?.allapi?.user_pre_month_commission
      : {}
  );
  console.log(user_pre_month_commission);

  useEffect(() => {
    dispatch(allapiAction.getuserBussnissMonth({}));
    dispatch(allapiAction.userCommissionpreviousmonth({}));
    // dispatch(allapiAction.getuserdeshboardData({}));
    // dispatch(allapiAction.getprofile({}));
    // dispatch(allapiAction.sellerDirectTeam({}));

    return () => {};
  }, []);

  // const loadOptionsPalyer = async (search, loadedOptions, page) => {
  //   const response = await fetch(
  //     URL.API_BASE_URL +
  //       `/publicApi/getuserforlinsense?search=${search}&page=${page}&user_id=${profile?.referId}`
  //   );
  //   const responseJSON = await response.json();

  //   const options = responseJSON?.results?.map((data) => {
  //     data.value = data?.id;
  //     data.label = data?.fullName;

  //     return data;
  //   });

  //   return {
  //     options: options,
  //     hasMore: responseJSON.has_more,
  //     additional: {
  //       page: page + 1,
  //     },
  //   };
  // };

  function getMonthList() {
    const startDate = new Date("2021-08-01");
    const currentDate = new Date();

    // Calculate the month one less than the current month
    currentDate.setMonth(currentDate.getMonth() - 1);

    const monthsList = [];

    while (startDate <= currentDate) {
      monthsList.push(
        startDate.toLocaleString("default", { month: "short", year: "numeric" })
      );

      startDate.setMonth(startDate.getMonth() + 1);
    }

    return monthsList;
  }

  // useEffect(() => {
  //   const months = getMonthList();
  //   console.log(months);
  // }, []);
  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  const [tab, setTab] = useState("day");
  const proStatus =
    seller_update_details?.status == 1
      ? 0
      : seller_update_details?.status == 2
      ? 25
      : seller_update_details?.status == 3
      ? 50
      : seller_update_details?.status == 4
      ? 75
      : seller_update_details?.status == 5
      ? 100
      : 100;

  const [userselete, setuserselete] = useState(null);
  const [userselete2, setuserselete2] = useState(null);
  const [userselete3, setuserselete3] = useState(null);
  const [userselete4, setuserselete4] = useState(null);
  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getuserforlinsense?search=${search}&page=${pages?.page}&user_id=${seller_update_details?.referId}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?.id;
      data.label = data?.referId + " - " + data?.fullName;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const hendletoAssLicence = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );

      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }

      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence2 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete2) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete2?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence3 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete3) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete3?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );

      // console.log(donedata);
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence4 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete4) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete4?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };
  const router = useNavigate();
  return (
    <div>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <BounceLoader color="#0D6EFD" />
        </div>
      ) : (
        <div>
          <Sidebar />

          <main className="main-wrap">
            <HeaderAccount />

            <section className="content-main">
              {/* <Confetti /> */}
              <div className="content-header">
                <div>
                  <h2 className="content-title card-title">Dashboard</h2>

                  {/* <div className="row">
                    <div className="col-12 col-sm-4">
                      <div style={{ width: "250px" }}>
                        <img
                          src="/founder1.png"
                          alt="DashBoard"
                          style={{ width: "100%" }}
                        />
                      </div>

                      <p style={{ fontSize: "20px", color: "black" }}>
                        Wishes from Founder & CEO
                      </p>
                    </div>
                    <div className="col-sm-8 col-12">
                      <p
                        className="blink-soft"
                        style={{
                          fontSize: "20px",
                          color: "#fa9532",
                          marginBottom: "10px",
                        }}
                      >
                        Congratulations to everyone on the completion of 3 years
                        of rilindia.biz Best wishes from The CEO and founder of
                        the company.
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* 
              <div className="">
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mb-2">
                <span className="required">Select User</span>
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="popover"
                  data-bs-trigger="hover"
                  data-bs-html="true"
                  data-bs-content="Permission names is required to be unique."
                ></i>
              </label>
              <AsyncPaginate
                value={userselete}
                loadOptions={loadOptionsPalyer}
                onChange={setuserselete}
                additional={{
                  page: 1,
                }}
              />
            </div>
          </div> */}

              <div className="row">
                <div className="col-lg-12">
                  <div id="profile_content">
                    <div class="row">
                      <div
                        class="col-md-12 dashboard-hides"
                        style={{ display: "none" }}
                      >
                        <i class="fa fa-spinner"></i>
                      </div>

                      <div
                        class="col-md-12 dashboard-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row mb-5">
                          {/* <div class="col-md-12">
                            <ul
                              class="profile_ul"
                              style={{ background: "#025295" }}
                            >
                              <li style={{ marginBottom: "15px" }}>
                                <a href="#">
                                  <b
                                    className="blink-soft"
                                    style={{
                                      fontSize: "15px",
                                      color: "black",
                                      marginBottom: "10px",
                                    }}
                                  >
                                   
                                    Notice:-
                                  </b>
                                </a>
                              </li>
                              <a class="pnav_my_team" href="#">
                                <li style={{ textAlign: "left" }}>
                                  <b style={{ color: "white" }}>
                                    <span
                                      style={{
                                        fontWeight: "800",
                                        fontSize: "17px",
                                        color: "white",
                                      }}
                                    >
                                      Thank you for helping , make another great
                                      year of working together. We hope you keep
                                      growing together for many more years with
                                      us.
                                    </span>{" "}
                                  </b>
                                </li>
                              </a>
                            </ul>
                          </div> */}

                          {/* <div class="col-md-6">
                            <ul
                              class="profile_ul"
                              style={{ background: "#d8d82f" }}
                            >
                              <li>
                                <a href="#">
                                  <b
                                    style={{ fontSize: "31px", color: "white" }}
                                  >
                                    <i class="fa fa-user"></i>
                                    {deshboard?.team?.DirectTeam}{" "}
                                  </b>
                                </a>
                              </li>
                              <a class="pnav_my_direct_team" href="#">
                                <li style={{ textAlign: "right" }}>
                                  <b style={{ color: "white" }}>MY DOWNLINE</b>
                                </li>
                              </a>
                            </ul>
                          </div>
                          {deshboard && (
                            <div class="col-md-6">
                              <ul
                                class="profile_ul"
                                style={{ background: "green" }}
                              >
                                <li>
                                  <a href="#">
                                    <b
                                      style={{
                                        fontSize: "31px",
                                        color: "white",
                                      }}
                                    >
                                   
                                      <i class="fa fa-inr"> </i>
                                      {deshboard?.buss?.curM?.amount}
                                    </b>
                                  </a>
                                </li>
                                <a class="pnav_my_direct_team" href="#">
                                  <li style={{ textAlign: "right" }}>
                                    <b style={{ color: "white" }}>
                                      {" "}
                                      {deshboard &&
                                        moment(
                                          deshboard?.buss?.curM?.month
                                        ).format("MMM yyyy ")}{" "}
                                      Business
                                    </b>
                                  </li>
                                </a>
                              </ul>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-lg-4 ">
                    <div class="card1">
                      <div>
                        <span style={{ color: "rgb(24, 130, 178)" }}>
                          Commission
                        </span>

                        {/* <button class="butt">Click to view detail</button>
                        <i
                          class="fa fa-info-circle "
                          style={{
                            fontSize: "22px",
                            color: "rgb(24, 130, 178)",
                          }}
                        ></i> */}
                      </div>

                      <h4 class="mt-5">
                        {user_pre_month_commission?.commission ? (
                          <b>{user_pre_month_commission?.commission}</b>
                        ) : (
                          <b>0</b>
                        )}
                      </h4>
                      <span>Commission amount</span>
                    </div>
                  </div>
                  <div class="col-lg-4 ">
                    <div class="card2">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>
                          Licenses
                        </span>

                        {/* <i
                          class="fa fa-info-circle i1"
                          style={{
                            fontSize: "22px",
                            color: "rgb(24, 130, 178)",
                          }}
                        ></i> */}
                      </div>

                      <h4 class="mt-5">
                        {seller_update_details?.userlicence == "1" ||
                        seller_update_details?.addlicenseAdmin == "1" ? (
                          <b>4</b>
                        ) : (
                          <b>0</b>
                        )}
                      </h4>
                      <span> Total licenses</span>
                    </div>
                  </div>
                  <div class="col-lg-4 ">
                    <div
                      class="card3 "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        router("/home/orderbusiness");
                      }}
                    >
                      <div>
                        <span style={{ color: "rgb(250, 149, 50)" }}>
                          Business Volume
                        </span>

                        {/* <i
                          class="fa fa-info-circle ml-5"
                          style={{
                            fontSize: "22px",
                            color: "rgb(24, 130, 178)",
                          }}
                        ></i> */}
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.todaybuss}</b>
                      </h4>
                      <span>Daily</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 ">
                    <div class="card4">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>Team</span>
                        {/* 
                        <i
                          class="fa fa-info-circle i1"
                          style={{
                            fontSize: "22px",
                            color: "rgb(24, 130, 178)",
                          }}
                        ></i> */}
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.allTeam}</b>
                      </h4>
                      <span> Total Team</span>
                    </div>
                  </div>
                  <div class="col-lg-4 ">
                    <div class="card5">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>Team</span>

                        {/* <i
                          class="fa fa-info-circle i1"
                          style={{
                            fontSize: "22px",
                            color: "rgb(24, 130, 178)",
                          }}
                        ></i> */}
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.DirectTeam}</b>
                      </h4>
                      <span> Direct Team </span>
                    </div>
                  </div>
                  <div class="col-lg-4 ">
                    <div class="card5">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>
                          Wallet
                        </span>
                        {/* 
                        <i
                          class="fa fa-info-circle i1"
                          style={{
                            fontSize: "22px",
                            color: "rgb(24, 130, 178)",
                          }}
                        ></i> */}
                      </div>

                      <h4 class="mt-5">
                        <b>{seller_update_details?.balance}</b>
                      </h4>
                      <span> Wallet </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div id="profile_content">
                    {" "}
                    {/* <div
                      class="information-title"
                      style={{ marginBottom: "0px" }}
                    >
                      Profile Information
                    </div> */}
                    <div class="row">
                      {/* <div class="col-md-12">
                        <div
                          class="recent-post"
                          style={{
                            background: "#fff",
                            border: "1px solid #e0e0e0",
                          }}
                        >
                          <div class="media">
                            <a
                              class="pull-left media-link"
                              href="#"
                              style={{ height: "124px" }}
                            >
                              <div
                                class="media-object img-bg"
                                id="blah"
                                // style="background-image: url('https://rilindia.biz/uploads/user_image/default.jpg'); background-size: cover;background-position-x: center; background-position-y: top; width: 100px; height: 124px;"
                              ></div>
                              <form
                                action="https://rilindia.biz/registration/change_picture/2"
                                class=""
                                method="post"
                                id="fff"
                                enctype="multipart/form-data"
                                accept-charset="utf-8"
                              >
                                <input
                                  type="hidden"
                                  name="csrf_test_name"
                                  value="16d6616dc83ab9da6650ca73a6a3d0ee"
                                />
                                <span id="inppic" class="set_image">
                                  <label class="" for="imgInp">
                                    <span>
                                      <i
                                        class="fa fa-pencil"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="imgInp"
                                    name="img"
                                  />
                                </span>
                                <span id="savepic" style={{ display: "none" }}>
                                  <span
                                    class="signup_btn"
                                    onclick="abnv('inppic'); change_state('normal');"
                                    data-ing="Saving..."
                                    data-success="Profile Picture Saved Successfully!"
                                    data-unsuccessful="Edit Failed! Try Again!"
                                    data-reload="no"
                                  >
                                    <span>
                                      <i
                                        class="fa fa-save"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </span>
                                  </span>
                                </span>
                              </form>
                            </a>
                            <div
                              class="media-body"
                              style={{ paddingRight: "20px" }}
                            >
                              <table
                                class="table table-condensed"
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <b>FCID</b>
                                    </td>
                                    <td>{profile?.referId}</td>
                                    <td>
                                      <b>Rank</b>
                                    </td>
                                    <td>
                                      {" "}
                                      {profile?.status == 0
                                        ? "fc"
                                        : profile?.status == 1
                                        ? "fr"
                                        : profile?.status == 2
                                        ? "br"
                                        : profile?.status == 3
                                        ? "ct"
                                        : profile?.status == 4
                                        ? "Mq"
                                        : "duke"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Name</b>
                                    </td>
                                    <td align="left">{profile?.fullName}</td>
                                    <td>
                                      <b>kyc</b>
                                    </td>
                                    <td>
                                      <span
                                        style={
                                          profile?.kycStatus == "Unapproved"
                                            ? { color: "red" }
                                            : { color: "green" }
                                        }
                                      >
                                        {profile?.kycStatus}
                                      </span>{" "}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b>Email</b>
                                    </td>
                                    <td>{profile?.email}</td>
                                    <td>
                                      <b>Contact No</b>
                                    </td>
                                    <td>{profile?.phone}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Wallet balance</b>
                                    </td>
                                    <td align="left">{profile?.balance}</td>
                                    <td>
                                      {" "}
                                      <b>Upline id</b>{" "}
                                    </td>
                                    <td>
                                      <span>{profile?.referBy}</span>{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Address</b>
                                    </td>
                                    <td>{profile?.address}</td>
                                    <td>
                                      <b>Country</b>
                                    </td>
                                    <td>{profile?.country}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>State</b>
                                    </td>
                                    <td>{profile?.state}</td>
                                    <td>
                                      <b>City</b>
                                    </td>
                                    <td>{profile?.city}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div
                        class="col-md-12 dashboard-hides"
                        style={{ display: "none" }}
                      >
                        <i class="fa fa-spinner"></i>
                      </div>

                      <div
                        class="col-md-12 dashboard-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row mb-5">
                          <div class="col-md-6">
                            <ul
                              class="profile_ul"
                              style={{ background: "blue" }}
                            >
                              <li>
                                <a href="#">
                                  <b
                                    style={{ fontSize: "31px", color: "white" }}
                                  >
                                    <i class="fa fa-user"></i>{" "}
                                    {deshboard?.team?.allTeam}{" "}
                                  </b>
                                </a>
                              </li>
                              <a class="pnav_my_team" href="#">
                                <li style={{ textAlign: "right" }}>
                                  <b style={{ color: "white" }}>TOTAL USER</b>
                                </li>
                              </a>
                            </ul>
                          </div>

                          <div class="col-md-6">
                            <ul
                              class="profile_ul"
                              style={{ background: "#d8d82f" }}
                            >
                              <li>
                                <a href="#">
                                  <b
                                    style={{ fontSize: "31px", color: "white" }}
                                  >
                                    <i class="fa fa-user"></i>
                                    {deshboard?.team?.DirectTeam}{" "}
                                  </b>
                                </a>
                              </li>
                              <a class="pnav_my_direct_team" href="#">
                                <li style={{ textAlign: "right" }}>
                                  <b style={{ color: "white" }}>MY DOWNLINE</b>
                                </li>
                              </a>
                            </ul>
                          </div>
                          {deshboard && (
                            <div class="col-md-6">
                              <ul
                                class="profile_ul"
                                style={{ background: "green" }}
                              >
                                <li>
                                  <a href="#">
                                    <b
                                      style={{
                                        fontSize: "31px",
                                        color: "white",
                                      }}
                                    >
                                      {/* <i class="fa fa-user"></i> */}
                                      <i class="fa fa-inr"> </i>
                                      {deshboard?.buss?.curM?.amount}
                                    </b>
                                  </a>
                                </li>
                                <a class="pnav_my_direct_team" href="#">
                                  <li style={{ textAlign: "right" }}>
                                    <b style={{ color: "white" }}>
                                      {" "}
                                      {deshboard &&
                                        moment(
                                          deshboard?.buss?.curM?.month
                                        ).format("MMM yyyy ")}{" "}
                                      Business
                                    </b>
                                  </li>
                                </a>
                              </ul>
                            </div>
                          )}
                        </div>

                        {/* <div class="row mt-5">
                          <div class="col-md-12">
                            <div class="row" style={{ display: "none" }}>
                              <div class="col-md-12">
                                <div class="form-group col-md-4">
                                  <label>From Date</label>
                                  <input
                                    type="date"
                                    class="form-control from_date"
                                  />
                                  <span
                                    class="err_from_date"
                                    style={{ color: "red" }}
                                  ></span>
                                </div>
                                <div class="form-group col-md-4">
                                  <label>To Date</label>
                                  <input
                                    type="date"
                                    class="form-control to_date"
                                  />
                                  <span
                                    class="err_to_date"
                                    style={{ color: "red" }}
                                  ></span>
                                </div>
                                <div class="form-group col-md-4">
                                  <button
                                    class="btn btn-success monthly_sale"
                                    style={{ marginTop: "30px", width: "100%" }}
                                  >
                                    Find
                                  </button>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group col-md-12">
                                  <label
                                    class="from-to-sale"
                                    style={{ color: "green" }}
                                  ></label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div
                                  class="widget widget-categories"
                                  style={{ paddingBottom: "25px" }}
                                >
                                  <ul class="profile_ul">
                                    <li>
                                      <a href="#">
                                        {deshboard &&
                                          moment(
                                            deshboard?.buss?.curM?.month
                                          ).format("MMM yyyy")}
                                        <b>
                                          <i class="fa fa-inr"> </i>
                                          {deshboard?.buss?.curM?.amount}
                                        </b>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        {deshboard &&
                                          moment(
                                            deshboard?.buss?.lastM?.month
                                          ).format("MMM yyyy")}
                                        :
                                        <b>
                                          <i class="fa fa-inr"> </i>
                                          {deshboard?.buss?.lastM?.amount}
                                        </b>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        {deshboard &&
                                          moment(
                                            deshboard?.buss?.lastM2?.month
                                          ).format("MMM yyyy")}
                                        :
                                        <b>
                                          <i class="fa fa-inr"> </i>
                                          {deshboard?.buss?.lastM2?.amount}
                                        </b>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        {deshboard &&
                                          moment(
                                            deshboard?.buss?.lastM3?.month
                                          ).format("MMM yyyy")}{" "}
                                        :
                                        <b>
                                          <i class="fa fa-inr"> </i>
                                          {deshboard?.buss?.lastM3?.amount}
                                        </b>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        {deshboard &&
                                          moment(
                                            deshboard?.buss?.lastM4?.month
                                          ).format("MMM yyyy")}{" "}
                                        :
                                        <b>
                                          <i class="fa fa-inr"> </i>
                                          {deshboard?.buss?.lastM4?.amount}
                                        </b>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        {deshboard &&
                                          moment(
                                            deshboard?.buss?.lastM5?.month
                                          ).format("MMM yyyy")}{" "}
                                        :
                                        <b>
                                          <i class="fa fa-inr"> </i>
                                          {deshboard?.buss?.lastM5?.amount}
                                        </b>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        {deshboard &&
                                          moment(
                                            deshboard?.buss?.lastM6?.month
                                          ).format("MMM yyyy")}{" "}
                                        :
                                        <b>
                                          <i class="fa fa-inr"> </i>
                                          {deshboard?.buss?.lastM6?.amount}
                                        </b>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div
                        class="col-md-12 top3seller-hides"
                        style={{ display: "none" }}
                      >
                        <i class="fa fa-spinner"></i>
                      </div>

                      {/* <div
                        class="col-md-12 top3seller-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <h3 class="block-title">
                              <span>Top 3 Seller</span>
                            </h3>
                            <div
                              class="widget widget-categories"
                              style={{ paddingBottom: "25px" }}
                            >
                              <ul class="profile_ul">
                                <li>
                                  <a href="#">
                                    RIL145056381 : Prashant Sanodiya :{" "}
                                    <b>Top - 1</b>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    RIL26393720 : Sanjay singh Na :{" "}
                                    <b>Top - 2</b>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    RIL5015447 : Girraj singh Nigam :{" "}
                                    <b>Top - 3</b>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div
                        class="col-md-12 top3seller-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <h3 class="block-title">
                              <span>Top 3 Seller</span>
                            </h3>
                            <div
                              class="widget widget-categories"
                              style={{ paddingBottom: "25px" }}
                            >
                              <div className="profile_ul">
                                <div class="container-fluid">
                                  <div class="row">
                                    <div class="col-lg-12 col-md-12 mb-4">
                                      <div
                                        class="card-round   h-100 py-2"
                                        style={{
                                          backgroundImage:
                                            "url(/assets/imgs/top-seller-background.png)",
                                        }}
                                      >
                                        <div class="card-body">
                                         
                                          <div class="row no-gutters align-items-center text-center mt-5">
                                            {top_three?.Topbuss &&
                                              top_three?.Topbuss?.map(
                                                (data, i) => {
                                                  return i == 0 ? (
                                                    <div
                                                      class="col-sm-4 mt-3"
                                                      key={i}
                                                    >
                                                      <div class="logoman"></div>
                                                      <div class="logoma">
                                                        <div class="topSellerRankDiv crown">
                                                          <span class="TopsellerRank">
                                                            {i + 1}
                                                          </span>
                                                        </div>
                                                        <img
                                                          src="/assets/imgs/image-design.png"
                                                          alt=""
                                                          className="logomaImg1"
                                                        />
                                                        <img
                                                          src={
                                                            data?.profile_image
                                                              ? URL?.API_BASE_URL +
                                                                data?.profile_image
                                                              : "/assets/imgs/people/avatar-2.png"
                                                          }
                                                          // src="https://png.pngtree.com/png-clipart/20230529/original/pngtree-handsome-man-cartoon-logo-png-image_9173395.png"
                                                          class="rounded-circle pt-1 logomaImg"
                                                          alt="top_seller_1"
                                                        />
                                                      </div>
                                                      <div class="d-flex justify-content-center">
                                                        <h6 class="name_box   rounded-lg">
                                                          {data?.fullName}
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      class="col-sm-4 mt-3"
                                                      key={i}
                                                    >
                                                      <div class="logoman"></div>
                                                      <div class="logoma">
                                                        <div class="topSellerRankDiv  ">
                                                          <span class="TopsellerRank">
                                                            {i + 1}
                                                          </span>
                                                        </div>
                                                        <img
                                                          src="/assets/imgs/image-design.png"
                                                          alt=""
                                                          className="logomaImg1"
                                                        />
                                                        <img
                                                          src={
                                                            data?.profile_image
                                                              ? URL?.API_BASE_URL +
                                                                data?.profile_image
                                                              : "/assets/imgs/people/avatar-2.png"
                                                          }
                                                          // src="https://png.pngtree.com/png-clipart/20230529/original/pngtree-handsome-man-cartoon-logo-png-image_9173395.png"
                                                          class="rounded-circle pt-1 logomaImg"
                                                          alt="top_seller_1"
                                                        />
                                                      </div>
                                                      <div class="d-flex justify-content-center">
                                                        <h6 class="name_box    rounded-lg">
                                                          {data?.fullName}
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}

                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* {lead_achiver?.length > 0 && (
                        <div
                          class="col-md-12 top3seller-shows"
                          style={{ marginTop: "15px" }}
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <h3 class="block-title">
                                <span>Led Achivers</span>
                              </h3>
                              <div
                                class="widget widget-categories"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="profile_ul">
                                  <div class="container-fluid">
                                    <div class="row">
                                      {lead_achiver &&
                                        lead_achiver?.map((data, i) => {
                                          return (
                                            <div class="col-lg-12 col-md-12 mb-2 ldbox">
                                              <div className="row align-items-center">
                                                <div className="col-sm-1 col-3">
                                                  <div className="profile_ulv">
                                                    <h6 className="mt-3 ms-3">
                                                      {i + 1}
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div className="col-sm-11 col-9">
                                                  <div className="profile_ulv">
                                                    <div className="row align-items-center">
                                                      <div className="col-sm-1 col-3">
                                                        <div>
                                                          <img
                                                            src={
                                                              data?.userId
                                                                ?.profile_image
                                                                ? URL?.API_BASE_URL +
                                                                  data?.userId
                                                                    ?.profile_image
                                                                : "/assets/imgs/people/avatar-2.png"
                                                            }
                                                            // src="/assets/imgs/people/avatar-2.png"
                                                            alt=""
                                                            style={{
                                                              width: "50px",
                                                              height: "50px",
                                                              borderRadius:
                                                                "50%",
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-sm-11 col-8">
                                                        <h6>
                                                          {
                                                            data?.userId
                                                              ?.referId
                                                          }{" "}
                                                          -{" "}
                                                          {
                                                            data?.userId
                                                              ?.fullName
                                                          }
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                      {/* {get_leader_board_c_m?.MarqusesLeader?.length > 0 && (
                        <div
                          class="col-md-12 top3seller-shows"
                          style={{ marginTop: "15px" }}
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <h3 class="block-title">
                                <span>Mq Leader Board</span>
                              </h3>
                              <div
                                class="widget widget-categories"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="profile_ul">
                                  <div class="container-fluid">
                                    <div class="row">
                                      {get_leader_board_c_m?.MarqusesLeader &&
                                        get_leader_board_c_m?.MarqusesLeader?.map(
                                          (data, i) => {
                                            return (
                                              <div class="col-lg-12 col-md-12 mb-2 ldbox">
                                                <div className="row align-items-center">
                                                  <div className="col-sm-1 col-3">
                                                    <div className="profile_ulv">
                                                      <h6 className="mt-3 ms-3">
                                                        {i + 1}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div className="col-sm-11 col-9">
                                                    <div className="profile_ulv">
                                                      <div className="row align-items-center">
                                                        <div className="col-sm-1 col-3">
                                                          <div>
                                                            <img
                                                              src={
                                                                data?.profile_image
                                                                  ? URL?.API_BASE_URL +
                                                                    data?.profile_image
                                                                  : "/assets/imgs/people/avatar-2.png"
                                                              }
                                                              // src="/assets/imgs/people/avatar-2.png"
                                                              alt=""
                                                              style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                borderRadius:
                                                                  "50%",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-sm-11 col-8">
                                                          <h6>
                                                            {data?.referId} -{" "}
                                                            {data?.fullName}
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}

                      {/* {get_leader_board_c_m?.countLeader?.length > 0 && (
                        <div
                          class="col-md-12 top3seller-shows"
                          style={{ marginTop: "15px" }}
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <h3 class="block-title">
                                <span>Count Leader Board</span>
                              </h3>
                              <div
                                class="widget widget-categories"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="profile_ul">
                                  <div class="container-fluid">
                                    <div class="row">
                                      {get_leader_board_c_m?.countLeader &&
                                        get_leader_board_c_m?.countLeader?.map(
                                          (data, i) => {
                                            return (
                                              <div class="col-lg-12 col-md-12 mb-2 ldbox">
                                                <div className="row align-items-center">
                                                  <div className="col-sm-1 col-3">
                                                    <div className="profile_ulv">
                                                      <h6 className="mt-3 ms-3">
                                                        {i + 1}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div className="col-sm-11 col-9">
                                                    <div className="profile_ulv">
                                                      <div className="row align-items-center">
                                                        <div className="col-sm-1 col-3">
                                                          <div>
                                                            <img
                                                              src={
                                                                data?.profile_image
                                                                  ? URL?.API_BASE_URL +
                                                                    data?.profile_image
                                                                  : "/assets/imgs/people/avatar-2.png"
                                                              }
                                                              // src="/assets/imgs/people/avatar-2.png"
                                                              alt=""
                                                              style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                borderRadius:
                                                                  "50%",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="col-sm-11 col-8">
                                                          <h6>
                                                            {data?.referId} -{" "}
                                                            {data?.fullName}
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                      {/* 
                      <div
                        class="col-md-12 top3seller-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <h3 class="block-title">
                              <span>Leader Board</span>
                            </h3>
                            <div
                              class="widget widget-categories"
                              style={{ paddingBottom: "25px" }}
                            >
                              <div className="profile_ul">
                                <div class="container-fluid">
                                  <div class="row">
                                    {top_three?.leaderBoard &&
                                      top_three?.leaderBoard?.map((data, i) => {
                                        return (
                                          <div class="col-lg-12 col-md-12 mb-2 ldbox">
                                            <div className="row align-items-center">
                                              <div className="col-sm-1 col-3">
                                                <div className="profile_ulv">
                                                  <h6 className="mt-3 ms-3">
                                                    {i + 1}
                                                  </h6>
                                                </div>
                                              </div>
                                              <div className="col-sm-11 col-9">
                                                <div className="profile_ulv">
                                                  <div className="row align-items-center">
                                                    <div className="col-sm-1 col-3">
                                                      <div>
                                                        <img
                                                          src={
                                                            data?.profile_image
                                                              ? URL?.API_BASE_URL +
                                                                data?.profile_image
                                                              : "/assets/imgs/people/avatar-2.png"
                                                          }
                                                          // src="/assets/imgs/people/avatar-2.png"
                                                          alt=""
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                            borderRadius: "50%",
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-sm-11 col-8">
                                                      <h6>{data?.fullName}</h6>
                                                    </div>
                                                   
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* sdfkjsdfjhk */}
                      <div
                        class="col-md-12 top3seller-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <h3 class="block-title">
                              <span>Rank</span>
                            </h3>
                            <div
                              class="widget widget-categories "
                              style={{ paddingBottom: "25px" }}
                            >
                              <div className="profile_ul1">
                                <div class="container mb-5 mt-5 star_overflow">
                                  {/* <div class="range"></div> */}
                                  <div class="progress">
                                    <div
                                      class="progress-bar range"
                                      role="progressbar"
                                      style={{ width: proStatus + "%" }}
                                      aria-valuenow={proStatus}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                  <div class="range_star d-flex justify-content-between">
                                    <img
                                      src={
                                        seller_update_details?.status >= 1
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    <img
                                      src={
                                        seller_update_details?.status >= 2
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    <img
                                      src={
                                        seller_update_details?.status >= 3
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    <img
                                      src={
                                        seller_update_details?.status >= 4
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    <img
                                      src={
                                        seller_update_details?.status >= 5
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    {/* <img
                                      src="/assets/imgs/startyellow.jpg"
                                      class="rounded-circle"
                                      alt="star_icon"
                                    /> */}
                                  </div>
                                  <div class="d-flex justify-content-between text-secondary">
                                    <small class="text-center star_p">
                                      <b>Fr</b>
                                      <br />
                                      {seller_update_details?.Userchaindata
                                        ?.fr_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.fr_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                      {/* Aug 8, 18, 07:34 pm */}
                                    </small>
                                    <small class="text-center star_p">
                                      <b>Br</b>
                                      <br />
                                      {seller_update_details?.Userchaindata
                                        ?.br_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.br_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                    </small>
                                    <small class="text-center star_p">
                                      <b>Ct</b>
                                      <br />
                                      {seller_update_details?.Userchaindata
                                        ?.ct_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.ct_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                    </small>
                                    <small class="text-center star_p">
                                      <b>Mq</b>
                                      <br />
                                      {seller_update_details?.Userchaindata
                                        ?.mq_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.mq_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                    </small>
                                    <small class="text-center star_p">
                                      <b>Duke</b> <br />
                                      {seller_update_details?.Userchaindata
                                        ?.duke_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.duke_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                    </small>
                                    {/* <small class="text-center star_p">
                                    <b>ABO</b>
                                    <br />
                                    Aug 8, 18, 07:34 pm
                                  </small>
                                  <small class="text-center star_p">
                                    <b>Veteran ABO</b>
                                    <br />
                                    Aug 8, 18, 07:34 pm
                                  </small> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div
                        class="  ant-card businessRecord ant-card-bordered ant-card-no-hovering ant-card-wider-padding ant-card-padding-transition "
                        style={{
                          backgroundColor: "white",
                          borderRadius: "30px",
                          // width: "90%",
                          // margin: "4.5rem",
                        }}
                      >
                        <div class="ant-card-head">
                          <div
                            class="ant-card-head-title ml-5"
                            style={{ fontWeight: "bold", fontSize: "14px" }}
                          >
                            Business Records
                          </div>
                          <hr />
                        </div>
                        <div class="ant-card-body">
                          <div style={{ overflow: "auto" }}>
                            <table style={{ width: "100%" }}>
                              <thead>
                                <tr>
                                  {get_last_three_month &&
                                    get_last_three_month?.map((data, i) => {
                                      console.log(data);
                                      return (
                                        <th
                                          style={{ borderLeft: "2px dotted" }}
                                        >
                                          {data?.endDate &&
                                            moment(data?.endDate).format(
                                              "MMM yyyy"
                                            )}{" "}
                                          -cycle {data?.cycle}
                                        </th>
                                      );
                                    })}
                                  
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {get_last_three_month &&
                                    get_last_three_month?.map((data, i) => {
                                      console.log(data);
                                      return (
                                        <td
                                          style={{ borderLeft: "2px dotted" }}
                                        >
                                          ₹ {data?.totalAmount}
                                         
                                        </td>
                                      );
                                    })}
                                
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> */}

                      {/* <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <h3 class="block-title">
                              <span>Level-1</span>
                            </h3>
                            <div
                              class="widget widget-categories"
                              style={{ paddingBottom: "25px" }}
                            >
                              <ul class="profile_ul">
                                <li>
                                  <a href="#">
                                    FR : <b>0</b>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    BR : <b>0</b>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    CT : <b>0</b>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    MQ : <b>0</b>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6" style={{ display: "none" }}>
                            <h3 class="block-title">
                              <span>Level-2</span>
                            </h3>
                            <div
                              class="widget widget-categories"
                              style={{ paddingBottom: "25px" }}
                            >
                              <ul class="profile_ul">
                                <li>
                                  <a href="#">
                                    FR : <b>0</b>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    BR : <b>0</b>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    CT : <b>0</b>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    MQ : <b>0</b>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* <ProgressBar currentLevel={2} totalLevels={5} /> */}
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 profile_ul mt-3">
                      <div>
                        <button
                          className={
                            tab == "day"
                              ? "btn btn-primary"
                              : "btn btn-secondary"
                          }
                          onClick={() => {
                            setTab("day");
                          }}
                        >
                          Day wise
                        </button>
                        <button
                          className={
                            tab == "Week"
                              ? "btn btn-primary"
                              : "btn btn-secondary"
                          }
                          onClick={() => {
                            setTab("Week");
                          }}
                        >
                          Week wise
                        </button>
                      </div>

                      {tab == "Week" && week_data?.length > 0 && (
                        <Chart
                          chartType="LineChart"
                          width="100%"
                          height="400px"
                          data={week_data}
                          options={{
                            title: "Week business",
                            curveType: "function",
                            legend: { position: "bottom" },
                          }}
                        />
                      )}
                      {tab == "day" && week_data?.length > 0 && (
                        <Chart
                          chartType="LineChart"
                          width="100%"
                          height="400px"
                          data={day_data}
                          options={{
                            title: "Day business",
                            curveType: "function",
                            legend: { position: "bottom" },
                          }}
                        />
                      )}
                    </div>
                    {/* <div className="col-12 mt-4 profile_ul">
                  
                  </div> */}
                  </div>
                </div>
                {/* <div className="col-lg-3">
                  <div className="card card-body mb-4">
                    <article className="icontext">
                     
                      <div className="text">
                        <h6 className="mb-1 card-title">Email View Count</h6>
                        <span>{deshboard_state?.emailCount}</span>
                       
                      </div>
                    </article>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card card-body mb-4">
                    <article className="icontext">
                     
                      <div className="text">
                        <h6 className="mb-1 card-title">Whatsapp View Count</h6>
                        <span>{deshboard_state?.whatsappCount}</span>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card card-body mb-4">
                    <article className="icontext">
                     
                      <div className="text">
                        <h6 className="mb-1 card-title">Total Products</h6>
                        <span>{deshboard_state?.proCount}</span>
                       
                      </div>
                    </article>
                  </div>
                </div> */}
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <div id="profile_content">
                    {" "}
                    <div
                      class="information-title"
                      style={{ marginBottom: "0px" }}
                    >
                      LICENSE
                    </div>
                  </div>
                </div>
              </div>

              {(seller_update_details?.userlicence == "1" ||
                seller_update_details?.addlicenseAdmin == "1") && (
                <div className="mt-3">
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount >= "1") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">create At</div>
                        <div className="col-2">FCID</div>
                        <div className="col-3">Allotted FCID</div>
                        <div className="col-3">Name</div>
                        <div className="col-2">Allotted on</div>
                        <div className="col-1">Submit</div>
                      </div>
                    </div>
                  )}
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount == "4") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.br_date &&
                            moment(
                              seller_update_details?.Userchaindata?.br_date
                            ).format("DD MMM YYYY")}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.referId}
                        </div>

                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license1_id ? (
                            seller_update_details?.Userchaindata?.license1_id
                              ?.referId
                          ) : (
                            <div className="fv-row mb-7">
                              <AsyncPaginate
                                value={userselete}
                                className="liceninput"
                                loadOptions={loadOptionsPalyer}
                                onChange={setuserselete}
                                additional={{
                                  page: 1,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license1_id
                            ? seller_update_details?.Userchaindata?.license1_id
                                ?.fullName
                            : "N/A"}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.Userchaindata?.license1_Date
                            ? moment(
                                seller_update_details?.Userchaindata
                                  ?.license1_Date
                              ).format("DD MMM YYYY")
                            : "N/A"}
                        </div>
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.license1_id ? (
                            ""
                          ) : (
                            <button
                              className="btn btn-primary lcbtn"
                              onClick={() => {
                                hendletoAssLicence(1);
                              }}
                            >
                              submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount >= "3") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.br_date &&
                            moment(
                              seller_update_details?.Userchaindata?.br_date
                            ).format("DD MMM YYYY")}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.referId}
                        </div>
                        {/* seller_update_details?.Userchaindata?.license1_id */}

                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license2_id ? (
                            seller_update_details?.Userchaindata?.license2_id
                              ?.referId
                          ) : (
                            <div className="fv-row mb-7">
                              <AsyncPaginate
                                value={userselete2}
                                className="liceninput"
                                loadOptions={loadOptionsPalyer}
                                onChange={setuserselete2}
                                additional={{
                                  page: 1,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license2_id
                            ? seller_update_details?.Userchaindata?.license2_id
                                ?.fullName
                            : "N/A"}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.Userchaindata?.license2_Date
                            ? moment(
                                seller_update_details?.Userchaindata
                                  ?.license2_Date
                              ).format("DD MMM YYYY")
                            : "N/A"}
                        </div>
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.license2_id ? (
                            ""
                          ) : (
                            <button
                              className="btn btn-primary lcbtn"
                              onClick={() => {
                                hendletoAssLicence2(2);
                              }}
                            >
                              submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount >= "2") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.br_date &&
                            moment(
                              seller_update_details?.Userchaindata?.br_date
                            ).format("DD MMM YYYY")}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.referId}
                        </div>
                        {/* seller_update_details?.Userchaindata?.license1_id */}

                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license3_id ? (
                            seller_update_details?.Userchaindata?.license3_id
                              ?.referId
                          ) : (
                            <div className="fv-row mb-7">
                              <AsyncPaginate
                                value={userselete3}
                                className="liceninput"
                                loadOptions={loadOptionsPalyer}
                                onChange={setuserselete3}
                                additional={{
                                  page: 1,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license3_id
                            ? seller_update_details?.Userchaindata?.license3_id
                                ?.fullName
                            : "N/A"}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.Userchaindata?.license3_Date
                            ? moment(
                                seller_update_details?.Userchaindata
                                  ?.license3_Date
                              ).format("DD MMM YYYY")
                            : "N/A"}
                        </div>
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.license3_id ? (
                            ""
                          ) : (
                            <button
                              className="btn btn-primary lcbtn"
                              onClick={() => {
                                hendletoAssLicence3(3);
                              }}
                            >
                              submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount >= "1") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.br_date &&
                            moment(
                              seller_update_details?.Userchaindata?.br_date
                            ).format("DD MMM YYYY")}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.referId}
                        </div>
                        {/* seller_update_details?.Userchaindata?.license1_id */}

                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license4_id ? (
                            seller_update_details?.Userchaindata?.license4_id
                              ?.referId
                          ) : (
                            <div className="fv-row mb-7">
                              <AsyncPaginate
                                value={userselete4}
                                className="liceninput"
                                loadOptions={loadOptionsPalyer}
                                onChange={setuserselete4}
                                additional={{
                                  page: 1,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license4_id
                            ? seller_update_details?.Userchaindata?.license4_id
                                ?.fullName
                            : "N/A"}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.Userchaindata?.license4_Date
                            ? moment(
                                seller_update_details?.Userchaindata
                                  ?.license4_Date
                              ).format("DD MMM YYYY")
                            : "N/A"}
                        </div>
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.license4_id ? (
                            ""
                          ) : (
                            <button
                              className="btn btn-primary lcbtn"
                              onClick={() => {
                                hendletoAssLicence4(4);
                              }}
                            >
                              submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </section>

            {/* <Footerr /> */}
          </main>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
