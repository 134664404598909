import React from "react";
import Sliders from "../Home/Slider";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";
import HomeNews from "./HomeNews";
import HomeSlider from "./HomeSlider";
import Product1 from "./Product1";
import Confetti from "../Account/Confetti";
import Confetti2 from "../Account/Confetti2";

function HomeNewCom({ cat, sliderdate }) {
  return (
    <div>
      <HeaderMain />
      {/* <Confetti2 /> */}
      <Sliders sliderdate={sliderdate} />
      {/* <HomeSlider /> */}
      <Product1 />
      <HomeNews />
      <FooterMain />
    </div>
  );
}

export default HomeNewCom;
