import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ReactPaginate from "react-paginate";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import ImageUploading from "react-images-uploading";
// import ReactImageMagnify from "react-image-magnify";
import { toast } from "react-toastify";

 
import Select from "react-select";
// import { setUserName, setUserNumber } from "@/Utils/Auth/Token";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";

// import { useRouter } from "next/router";
// import Link from "next/link";
// import Head from "next/head";
import { setUserName, setUserNumber } from "../../Utils/Auth/Token";
// import Seo from "../Seo/Seo";

import BounceLoader from "react-spinners/BounceLoader";
// import Image from "next/image";

import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import TruncateText from "../TruncateText/TruncateText";
import { Link, useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { Image } from "react-bootstrap";
import HeaderMain from "../Layout/HeaderMain";
import FooterMain from "../Layout/FooterMain";

function MainCategory({ post }) {
  //   const data = useParams();
  const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const router = useNavigate();
  const { id } = useParams();

  const get_seller_ticket_list_public = useSelector((state) =>
    state?.allapi?.get_seller_product_list_public_by_cate
      ? state?.allapi?.get_seller_product_list_public_by_cate
      : {}
  );

  const all_sub_sub_categ_List_fil = useSelector((state) =>
    state?.allapi?.all_sub_sub_categ_List_fil
      ? state?.allapi?.all_sub_sub_categ_List_fil
      : {}
  );

  const loading = useSelector((state) =>
    state?.allapi?.loading ? state?.allapi?.loading : false
  );
  const loading1 = useSelector((state) =>
    state?.allapi?.loading1 ? state?.allapi?.loading1 : false
  );
  const get_seller_details = useSelector((state) =>
    state?.allapi?.get_seller_details ? state?.allapi?.get_seller_details : {}
  );
  const country_list = useSelector((state) =>
    state?.allapi?.country_list ? state?.allapi?.country_list : []
  );
  const city_list = useSelector((state) =>
    state?.allapi?.city_list ? state?.allapi?.city_list : []
  );

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);
  const [activeCate, setactiveCate] = useState("");

  const [pageNo2, setpageNo2] = useState(1);

  const hendletoPagination = () => {
    const finalPage = pageNo2 + 1;
    setpageNo2(finalPage);
    dispatch(allapiAction.getsubsubCategoryList(finalPage));
    return () => {};
  };

  const [selectedRange, setSelectedRange] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  // useEffect(() => {
  //   if (selectedRange) {
  //     const [min, max] = selectedRange.split('-').map(Number);
  //     const filtered = products.filter(product => product.price >= min && product.price <= max);
  //     setFilteredProducts(filtered);
  //   } else {
  //     setFilteredProducts(products);
  //   }
  // }, [selectedRange, products]);

  // const generateOptions = () => {
  //   const options = [];
  //   for (let i = 1001; i <= 3500; i += 1000) {
  //     const option = `${i}-${i + 499}`;
  //     options.push(
  //       <option key={option} value={option}>
  //         {`${i}-${i + 499}`}
  //       </option>
  //     );
  //   }
  //   return options;
  // };
  const generateOptions = () => {
    const options = [];
    const roundedLowestPrice = Math.floor((1650 - 1) / 500) * 500 + 1;
    for (let i = roundedLowestPrice; i <= 2800; i += 500) {
      const option = `${i}-${i + 499}`;
      options.push(
        <option key={option} value={option}>
          {`${i}-${i + 499}`}
        </option>
      );
    }
    return options;
  };

  console.log(all_sub_sub_categ_List_fil?.highestPriceProduct);
  console.log(all_sub_sub_categ_List_fil?.lowestPriceProduct);
  console.log(all_sub_sub_categ_List_fil?.lowestPriceProduct ? "1" : "2");
  console.log(all_sub_sub_categ_List_fil);

  const lowpc = all_sub_sub_categ_List_fil?.lowestPriceProduct
    ? all_sub_sub_categ_List_fil?.lowestPriceProduct
    : 1;
  const hehpc = all_sub_sub_categ_List_fil?.highestPriceProduct
    ? all_sub_sub_categ_List_fil?.highestPriceProduct
    : 1;

  const generateOptions5 = () => {
    const options = [];
    const roundedLowestPrice = Math.floor((lowpc - 1) / 500) * 500 + 1;
    for (let i = roundedLowestPrice; i <= hehpc; i += 500) {
      const option = `${i}-${i + 499}`;
      options.push(
        <div>
          <input
            class="box2"
            type="checkbox"
            id={option}
            name={option}
            // value="Bike"
            value={option}
            checked={selectedItemsRang.includes(option)}
            onChange={() => handleCheckboxChangeRang(option)}
          />
          <label for={option}>
            <span class="box3">{option}</span>
          </label>
        </div>
      );
    }
    return options;
  };

  const handleFilterChange = (range) => {
    setSelectedRange(range);
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (value) => {
    // Check if the item is already selected
    if (selectedItems.includes(value)) {
      // If selected, remove it from the array
      setSelectedItems(selectedItems.filter((item) => item !== value));
    } else {
      // If not selected, add it to the array
      setSelectedItems([value]);
    }
  };
  const [selectedItemsRang, setSelectedItemsRang] = useState([]);

  const handleCheckboxChangeRang = (value) => {
    // Check if the item is already selected
    if (selectedItemsRang.includes(value)) {
      // If selected, remove it from the array
      setSelectedItemsRang(selectedItemsRang.filter((item) => item !== value));
    } else {
      // If not selected, add it to the array
      setSelectedItemsRang([value]);
    }
  };

  const [selectedItemscate, setSelectedItemscate] = useState([]);

  const clearfilter = () => {
    setSelectedItemscate([]);
    setSelectedItems([]);
    setSelectedItemsRang([]);
  };
  console.log(selectedItemscate);
  const handleCheckboxChangecate = (value) => {
    // Check if the item is already selected
    if (selectedItemscate.includes(value)) {
      // If selected, remove it from the array
      setSelectedItemscate(selectedItemscate.filter((item) => item !== value));
    } else {
      // If not selected, add it to the array
      setSelectedItemscate([...selectedItemscate, value]);
    }
  };

  useEffect(() => {
    dispatch(
      allapiAction.getsellerProductListPublicebymaincategory2({
        id: id,
        page: 1,
        country: selectedOption?._id,
        city: selectedOptionCity?._id,
        orderby: selectedItems,
        subcate: selectedItemscate,
        rang: selectedItemsRang,
      })
    );
    // dispatch(allapiAction.getsellerprofile({ id: data?.id }));
    // dispatch(allapiAction.getsubsubcatehome({}));
    return () => {};
  }, [
    id,
    selectedOption,
    selectedItemscate,
    selectedOptionCity,
    selectedItems,
    selectedItemsRang,
  ]);

  useEffect(() => {
    dispatch(allapiAction.getsubsubCategoryList(id));
    // dispatch(allapiAction.getOneUserPerCountry());
    return () => {};
  }, [id]);

  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [pageNo, setpageNo] = useState(1);
  const headers = [
    { name: "Route Name", field: "routeName", sortable: true },
    { name: "From", field: "from", sortable: true },
    { name: "To", field: "to", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const comments = get_seller_ticket_list_public?.docs
    ? get_seller_ticket_list_public?.docs
    : [];
  const pageCount = get_seller_ticket_list_public?.totalPages
    ? get_seller_ticket_list_public?.totalPages
    : 0;

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.message.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.title.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice

    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const datad = event?.selected + 1;

    setpageNo(datad);
    window.scrollTo(0, 150);
    if (!search) {
      // dispatch(ShoetoggelAction.getusers(data))
      // dispatch(allapiAction.getsellerProductList(data));
      dispatch(
        allapiAction.getsellerProductListPublicebymaincategory2({
          id: id,
          page: datad,
          country: selectedOption?._id,
          city: selectedOptionCity?._id,
          orderby: selectedItems,
          subcate: selectedItemscate,
          rang: selectedItemsRang,
        })
      );
      //   dispatch(allapiAction.getsellerProductList(1));
      return () => {};
    } else {
      // dispatch(
      //   ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
      // );
      dispatch(allapiAction.searchSellerProduct({ key: search, page: datad }));
      return () => {};
    }
  };

  const [showView, setShowView] = useState(false);

  // const handleCloseDelete = () => setShowDelete(false);
  const handleCloseView = () => setShowView(false);

  const [dispImg, setDispImg] = useState("/assets/imgs/shop/product-16-2.jpg");
  const [productImage, setproductImage] = useState([]);
  const [productData, setproductData] = useState({});

  const get_product_details = useSelector((state) =>
    state?.allapi?.get_product_details ? state?.allapi?.get_product_details : {}
  );

  useEffect(() => {
    const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

    const allImg = get_product_details?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    if (get_product_details?.images !== undefined) {
      const allImgs = allImg?.filter((data) => {
        return !data.match("undefined");
      });

      const allimgMarge = [feImg, ...allImgs];
      setDispImg(feImg);
      setproductImage(allimgMarge);
    }
    setproductData(get_product_details);
  }, [get_product_details]);

  const hendleModalShowView = (e) => {
    setShowView(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    dispatch(allapiAction.productDetails(e?.pro_sulg));
    // dispatch(allapiAction.getsubsubcatehome({}));

    // const allimgMarge = [feImg, ];
    // setDispImg(feImg);
    // setproductImage(allimgMarge);

    // setproductData(e);
    return () => {};
  };

  const [showWhatsappIn, setShowWhatsappIn] = useState(false);
  const handleCloseWhatsappIn = () => setShowWhatsappIn(false);
  const handleOpneWhatsappIn = () => setShowWhatsappIn(true);

  const [whatInq, setwhatInq] = useState({ countryCode: "", whatsappNo: "" });
  const [emailInq, setemailInq] = useState({
    country: "",
    customerEmail: "",
    userName: "",
  });

  const hendleToWhatsapp = (e) => {
    const { name, value } = e.target;
    setwhatInq({ ...whatInq, [name]: value });
  };

  // start

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode1, setCountryCode1] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");

  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [countryCode12, setCountryCode12] = useState("");
  const [phoneNumber12, setPhoneNumber12] = useState("");
  const handlePhoneChange = (value) => {
    const phone = value || "";
    const phoneNumberObj = parsePhoneNumberFromString(phone);
    const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
    const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
    setCountryCode1(conCode);
    setPhoneNumber1(contry);
    setPhoneNumber(value);
  };

  const handlePhoneChange2 = (value) => {
    const phone = value || "";
    const phoneNumberObj = parsePhoneNumberFromString(phone);
    const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
    const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
    setCountryCode12(conCode);
    setPhoneNumber12(contry);
    setPhoneNumber2(value);
  };

  const hendleWhatsappIn = () => {
    // whatsappinquiresubmit

    if (!countryCode1) {
      toast.error("Please enter country code");
    } else if (!phoneNumber1) {
      toast.error("Please enter Number");
    } else if (!whatInq?.name) {
      toast.error("Please enter name");
    } else {
      dispatch(
        allapiAction.whatsappinquiresubmit({
          countryCode: countryCode1,
          whatsappNo: phoneNumber1,
          name: whatInq?.name,
          email: whatInq?.email,
          message: whatInq?.message,
          productName: productData?.title,
          productId: productData?._id,
          sellerId: productData?.sellerId?._id,
          sellerName: productData?.sellerId?.fullName,
          sellercountryCode: productData?.sellerId?.countryCode,
          sellerwhatsappNo: productData?.sellerId?.whatsappNumber,
        })
      );
      const userEmailData = JSON.stringify({
        countryCode: countryCode1,
        whatsappNo: phoneNumber1,
        name: whatInq?.name,
        email: whatInq?.email,
      });

      // whatsappNo: whatInq?.whatsappNo,
      setUserNumber(userEmailData);
      // dispatch(allapiAction.getsubsubcatehome({}));
      setShowWhatsappIn(false);
      setShowView(false);

      return () => {};
    }
  };

  // end

  const [showEmailIn, setShowEmailIn] = useState(false);
  const handleCloseEmailIn = () => setShowEmailIn(false);
  const handleOpneEmailIn = () => setShowEmailIn(true);

 

  const [subList, setsubList] = useState([]);
  const [subListCity, setsubListCity] = useState([]);

  useEffect(() => {
    const dropList = country_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?._id;

      return data;
    });

    setsubList(dropList);
  }, [country_list]);

  useEffect(() => {
    const dropList = city_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?._id;

      return data;
    });

    setsubListCity(dropList);
  }, [city_list]);
  const [subListCityfil, setsubListCityfil] = useState([]);

  useEffect(() => {
    // const found = subListCity.find(
    //   (element) => element?.countryCode == selectedOption?.isoCode
    // );

    dispatch(allapiAction.getOneUserPerCity({ country: selectedOption?._id }));
    return () => {};

    // const listFilCity =
    //   subListCity &&
    //   subListCity?.filter((data) => {
    //     return data?.countryCode == selectedOption?._id;
    //   });

    // setsubListCityfil(listFilCity);
  }, [selectedOption]);

  // useEffect(() => {
  //   // const found = subListCity.find(
  //   //   (element) => element?.countryCode == selectedOption?.isoCode
  //   // );
  //   // dispatch(allapiAction.getOneUserPerCity({country:selectedOption?._id}));
  //   // return () => {};
  //   // const listFilCity =
  //   //   subListCity &&
  //   //   subListCity?.filter((data) => {
  //   //     return data?.countryCode == selectedOption?._id;
  //   //   });
  //   // setsubListCityfil(listFilCity);
  // }, [selectedOption]);

  const hendleToEmail = (e) => {
    const { name, value } = e.target;
    setemailInq({ ...emailInq, [name]: value });
  };

  const hendleModalEmailView = (e) => {
    setShowEmailIn(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });
    const allImgs = allImg?.filter((data) => {
      return !data.match("undefined");
    });

    const allimgMarge = [feImg];
    setDispImg(feImg);
    setproductImage(allimgMarge);

    setproductData(e);
  };

  const hendleModalWhatsappView = (e) => {
    setShowWhatsappIn(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    const allImgs = allImg?.filter((data) => {
      return !data.match("undefined");
    });

    const allimgMarge = [feImg];
    setDispImg(feImg);
    setproductImage(allimgMarge);

    setproductData(e);
  };

  // start

  const hendleEmailIn = () => {
    // whatsappinquiresubmit

    if (!emailInq?.userName) {
      toast.error("Please enter name");
    } else if (!emailInq?.customerEmail) {
      toast.error("Please enter email");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailInq?.customerEmail)
    ) {
      toast.error("invalid email");
    } else if (!phoneNumber12) {
      toast.error("Please enter number");
    } else {
      dispatch(
        allapiAction.emailenquirysubmit({
          // country: "", customerEmail: "",userName:""
          countryCode: countryCode12,
          whatsappNo: phoneNumber12,
          message: emailInq?.message,
          customerEmail: emailInq?.customerEmail,
          userName: emailInq?.userName,
          productName: productData?.title,
          productId: productData?._id,
          sellerId: productData?.sellerId?._id,
          sellerName: productData?.sellerId?.fullName,
          sellerEmail: productData?.sellerId?.email,
          sellerwhatsappNo: productData?.sellerId?.whatsappNumber,
        })
      );
      const userEmailData = JSON.stringify({
        countryCode: countryCode12,
        whatsappNo: phoneNumber12,
        Name: emailInq?.userName,
        Email: emailInq?.customerEmail,
        // message: emailInq?.message,
      });

      setUserName(userEmailData);
      // dispatch(allapiAction.getsubsubcatehome({}));
      setShowEmailIn(false);
      setShowView(false);

      return () => {};
    }
  };

  useEffect(() => {
    const blogIds = localStorage.getItem("access_name");
    const blogIdss = blogIds !== null ? blogIds : "{}";
    const arr = JSON.parse(blogIdss);

    const blogIdsnum = localStorage.getItem("access_number");
    const blogIdssnum = blogIdsnum !== null ? blogIdsnum : "{}";
    const arrnum = JSON.parse(blogIdssnum);

    setPhoneNumber("+" + arrnum?.countryCode + arrnum?.whatsappNo);
    setPhoneNumber2("+" + arr?.countryCode + arr?.whatsappNo);

    setCountryCode1(arrnum?.countryCode);
    setPhoneNumber1(arrnum?.whatsappNo);
    setCountryCode12(arr?.countryCode);
    setPhoneNumber12(arr?.whatsappNo);

    setwhatInq({
      name: arrnum?.name,
      email: arrnum?.email,
    });
    setemailInq({
      // message: arr?.message,
      customerEmail: arr?.Email,
      userName: arr?.Name,
    });
  }, []);

  // end

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      pageUrl = window.location.href;
    }
  }

  const urltet = URL.API_BASE_URL_2 + "/category-products/";

  const handleImageError = (event) => {
    event.target.onerror = null; // Prevent infinite loop in case of fallback image also failing
    event.target.src = "/path/to/fallback-image.jpg"; // Provide a fallback image path
  };

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imageWidth = windowWidth <= 767 ? 150 : 300;
  const imageHeight = windowWidth <= 767 ? 150 : 229;

  return (
    <div>
      {/* <ScrollToTop /> */}
      {/* <Header /> */}
      {/* 
      <Seo
        pagLink={pageUrl}
        ogptitle={get_seller_ticket_list_public?.cate?.meta_title}
        seo_descriptions={get_seller_ticket_list_public?.cate?.meta_description}
        ogpimg={URL.API_BASE_URL + get_seller_ticket_list_public?.cate?.image}
        title={get_seller_ticket_list_public?.cate?.name}
        // titleSeo={get_page_details?.metaTag}
        // seo_descriptions={get_page_details?.metadescription}
      /> */}

      {/* <Head>
        <title> {post?.cate?.name}</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
   
        <link rel="canonical" href={urltet + id} />

        <meta name="title" content={post?.cate?.meta_title} />
        <meta name="descriptions" content={post?.cate?.meta_description} />
      

        <meta property="og:locale" content="en" />
        <meta property="og:url" content={urltet + id} />
        <meta property="og:site_name" content="Jewelslly" />
        <meta property="og:title" content={post?.cate?.meta_title} />
        <meta
          property="og:description"
          content={post?.cate?.meta_description}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={URL.API_BASE_URL + post?.cate?.image}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@jewelslly1" />
        <meta name="twitter:title" content={post?.cate?.meta_title} />
        <meta
          name="twitter:description"
          content={post?.cate?.meta_description}
        />
        <meta
          name="twitter:image"
          content={URL.API_BASE_URL + post?.cate?.image}
        />
        <meta name="twitter:image:alt" content={post?.cate?.name} />
      </Head> */}
      <HeaderMain />
      <Modal show={showView} size="xl" onHide={handleCloseView}>
        <Modal.Header closeButton>
          <Modal.Title> Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <BounceLoader color="#0D6EFD" />
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <div className="productPage__left">
                      <div className="productPage__sideImagesContainer ">
                        {productImage &&
                          productImage?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => setDispImg(item)}
                                className="sideImage"
                                // style={{
                                //   backgroundImage: `url(${
                                //     URL.API_BASE_URL + item
                                //   })`,
                                // }}
                              >
                                <Image
                                  src={item}
                                  alt={productData?.title + index}
                                  width={imageWidth}
                                  height={imageHeight}
                                  // onError={(e) => {
                                  //   e.target.onerror = null;
                                  //   e.target.src = "../assets/imgs/notfound.png";
                                  // }}
                                />
                              </div>
                            );
                          })}
                      </div>
                      <div className="productPage__displayImageContainer">
                        <div
                          className="productPage__displayImage"
                          // style={{
                          //   backgroundImage: `url(${
                          //     URL.API_BASE_URL + dispImg
                          //   })`,
                          // }}
                        >
                          <div style={{ width: "250px" }}>
                            <img
                              src={dispImg}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div>
                          {/* <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: true,
                                src: dispImg,
                                // sizes:
                                //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                              },
                              largeImage: {
                                src: dispImg,
                                width: 1200,
                                height: 1800,
                              },
                              // className: "imgViewS",
                              // imageStyle: {
                              //   maxHeight: "400px",
                              //   maxWidth: "100%",
                              //   width: "auto ",
                              //   margin: "auto",
                              // },
                              imageClassName: "imgViewS1",
                              // enlargedImageContainerDimensions: {
                              //   width: "200%",
                              //   height: "100%",
                              // },
                              enlargedImageStyle: {
                                width: "50px",
                              },
                              lensStyle: {
                                width: "20px",
                                height: "20px",
                              },
                              shouldUsePositiveSpaceLens: true,
                              shouldHideHintAfterFirstActivation: false,
                              // isHintEnabled: true,
                              lensStyle: {
                                backgroundColor: "rgba(0,0,0,.6)",
                              },
                              enlargedImagePosition: "over",
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 ">
                    <div className="detail-info pr-30 pl-30">
                      {/* <span className="stock-status out-stock"> Sale Off </span> */}
                      <h3 className="title-detail mb-30">
                        <a
                          // href=""
                          className="text-heading"
                        >
                          {productData?.title}
                        </a>
                      </h3>

                      <div className="detail-extralink mb-30"></div>
                      <div className="font-xs">
                        <ul>
                          <li className="mb-5">
                            Tag:{" "}
                            <span className="text-brand">
                              {productData?.tegs}, {productData?.tegs2}
                            </span>
                          </li>
                          <li className="mb-5">
                            Category:
                            <span className="text-brand">
                              {" "}
                              {productData?.categoryId?.name}
                            </span>
                          </li>
                          {/* <li className="mb-5">
                          Status:
                          <span className="text-brand">
                            {" "}
                            {productData?.status}
                          </span>
                        </li> */}
                          <li className="mb-5">
                            Description:
                            {/* <span className="text-brand"> */}{" "}
                            <div
                              className=" modalBoXContaint"
                              dangerouslySetInnerHTML={{
                                __html: productData?.description,
                              }}
                            ></div>
                          </li>
                        </ul>
                      </div>

                      <div className="product-extra-link2 ProBtn">
                        <Button variant="success" onClick={handleOpneEmailIn}>
                          Email
                        </Button>{" "}
                        {productData?.sellerId?.whatsappVerify && (
                          <Button
                            variant="success"
                            onClick={handleOpneWhatsappIn}
                          >
                            Whatsapp
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>

      {/* whatsapp model  */}
      <Modal show={showWhatsappIn} onHide={handleCloseWhatsappIn}>
        <Modal.Header closeButton>
          <Modal.Title>Whatsapp Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              {/* <div className="mb-3">
                <label for="title" className="form-label">
                  Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Your Name"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Name
                </label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  value={whatInq?.name}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Email
                </label>
                <input
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  type="email"
                  value={whatInq?.email}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="teg" className="form-label">
                  Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country="IN"
                  defaultCountry="IN"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter message"
                  type="text"
                  value={whatInq?.message}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>
              {/* <div className="mb-3">
                <label for="teg" className="form-label">
                  Inquire
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter Inquire"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseWhatsappIn}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleWhatsappIn();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* email model  */}
      <Modal show={showEmailIn} onHide={handleCloseEmailIn}>
        <Modal.Header closeButton>
          <Modal.Title>Email Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              {/* <div className="mb-3">
                <label for="title" className="form-label">
                  Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Your Name"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Your Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Name"
                  type="text"
                  value={emailInq?.userName}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Email
                </label>
                <input
                  name="customerEmail"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  value={emailInq?.customerEmail}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country="IN"
                  defaultCountry="IN"
                  value={phoneNumber2}
                  onChange={handlePhoneChange2}
                />
              </div>
              <div className="mb-3">
                <label for="teg" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter Message"
                  type="text"
                  value={emailInq?.message}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmailIn}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleEmailIn();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="row justify-content-between">
              <div className="breadcrumb col">
                {/* <a href="index.html" rel="nofollow">
                <i className="fi-rs-home mr-5"></i>home
              </a> */}
                <Link to={"/"}>
                  {" "}
                  <i className="fi-rs-home mr-5"></i>home
                </Link>
                <span> </span> {post?.cate?.name}
              </div>

              {/* <div className=" col text-end">
                <h1 className="cateheading">{post?.cate?.name}</h1>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container mb-30">
          <div className="row">
            {(selectedItemscate?.length > 0 ||
              selectedItemsRang?.length > 0 ||
              selectedItems?.length > 0) && (
              <div className="col-lg-1-5">
                <label class="filter-head">FILTERS</label>
                <span>
                  <hr class="vertical-div" />
                  <label
                    class="filter-head filter-head-clr"
                    onClick={() => {
                      clearfilter();
                    }}
                  >
                    {" "}
                    CLEAR
                  </label>
                </span>
              </div>
            )}
            <div className="col-lg-4-5">
              {selectedItems &&
                selectedItems?.map((data, i) => {
                  return (
                    <div
                      class="wrap-collabsible-filt"
                      key={i}
                      onClick={() => {
                        setSelectedItems([]);
                      }}
                    >
                      <label class="lbl-toggle-filt">Price : {data}</label>
                    </div>
                  );
                })}
              {selectedItemsRang &&
                selectedItemsRang?.map((data, i) => {
                  return (
                    <div
                      class="wrap-collabsible-filt"
                      key={i}
                      onClick={() => {
                        setSelectedItemsRang([]);
                      }}
                    >
                      <label class="lbl-toggle-filt">Price : {data}</label>
                    </div>
                  );
                })}
              {selectedItemscate &&
                selectedItemscate?.map((data, i) => {
                  const findcate = all_sub_sub_categ_List_fil?.docs?.find(
                    (item) => item._id === data
                  );
                  return (
                    <div
                      class="wrap-collabsible-filt"
                      key={i}
                      onClick={() => {
                        handleCheckboxChangecate(data);
                      }}
                    >
                      <label class="lbl-toggle-filt"> {findcate?.name}</label>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row flex-row-reverse">
            <div className="col-lg-4-5">
              {loading ? (
                ""
              ) : (
                <div className="shop-product-fillter">
                  <div className="totall-product">
                    <p>
                      We found{" "}
                      <strong className="text-brand">
                        {get_seller_ticket_list_public?.totalDocs}
                      </strong>{" "}
                      items for you!
                    </p>
                  </div>
                  <div className="sort-by-product-area"></div>
                </div>
              )}
              {get_seller_ticket_list_public?.totalDocs == 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <h3>Record Not found</h3>
                </div>
              )}
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <BounceLoader color="#0D6EFD" />
                </div>
              ) : (
                <div className="row product-grid">
                  {/* {commentsData &&
                    commentsData?.map((data, i) => {
                      console.log(data);
                      return (
                        <div
                          className="col-lg-1-5 col-md-4 col-6 col-sm-6"
                          key={i}
                        >
                          <div className="product-cart-wrap mb-30">
                            <div className="product-img-action-wrap">
                              <div className="product-img product-img-zoom">
                                <Link
                                  href={"/product-details/" + data?.pro_sulg}
                                  onClick={() => {
                                    //   navigate(
                                    //     "/product-details/" + data?.pro_sulg
                                    //   );
                                    dispatch(
                                      allapiAction.productclick({
                                        productId: data?._id,
                                        categoryId: data?.categoryId?._id,
                                        sellerId: data?.sellerId?._id,
                                        type: "product",
                                      })
                                    );
                                  }}
                                >
                                  <Image
                                    className="default-img"
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt={data?.title}
                                    width={imageWidth}
                                    height={imageHeight}
                                    // onError={(e) => {
                                    //   e.target.onerror = null;
                                    //   e.target.src =
                                    //     "/assets/imgs/notfound.png";
                                    // }}
                                  />
                                </Link>
                              </div>
                              <div className="product-action-1">
                                <a
                                  aria-label="Quick view"
                                  className="action-btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#quickViewModal"
                                  onClick={() => {
                                    hendleModalShowView(data);
                                  }}
                                >
                                  <i className="fi-rs-eye"></i>
                                </a>
                              </div>
                            </div>
                            <div className="product-content-wrap">
                              <div className="product-category">
                                <Link
                                  href={
                                    "/category-product/" +
                                    data?.categoryId?.cate_sulg
                                  }
                                  // onClick={() => {
                                  //   navigate(
                                  //     "/category-product/" +
                                  //       data?.categoryId?.cate_sulg
                                  //   );
                                  // }}
                                >
                                  {data?.categoryId?.name}
                                </Link>
                              </div>
                              <h2>
                                <Link
                                  href={"/product-details/" + data?.pro_sulg}
                                  onClick={() => {
                                    //   navigate(
                                    //     "/product-details/" + data?.pro_sulg
                                    //   );
                                    dispatch(
                                      allapiAction.productclick({
                                        productId: data?._id,
                                        categoryId: data?.categoryId?._id,
                                        sellerId: data?.sellerId?._id,
                                        type: "product",
                                      })
                                    );
                                  }}
                                >
                                  {data?.title}
                                </Link>
                              </h2>

                              <div className="product-card-bottom">
                                {value && (
                                  <div className="product-price">
                                    <span>₹{data?.afterdiscountprice}</span>
                                    <span className="old-price">
                                      ₹{data?.price}
                                    </span>
                                  </div>
                                )}
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })} */}
                  {commentsData &&
                    commentsData?.map((data, i) => {
                      const delaydata = i * 100;
                      return (
                        <div
                          class="col-md-3 col-6"
                          key={i}
                          data-aos="fade-right"
                          data-aos-delay={delaydata}
                        >
                          <div class="card" style={{ width: "auto" }}>
                            <div class="product-grid">
                              <div class="product-image">
                                <Link to={"/product-details/" + data?.pro_sulg}>
                                  {/* <img
                                    class="pic-1"
                                    src={URL.API_BASE_URL + data?.featureImage}
                                  /> */}
                                  <Image
                                    className="default-img"
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt={data?.title}
                                    width={imageWidth}
                                    height={imageHeight}
                                    // onError={(e) => {
                                    //   e.target.onerror = null;
                                    //   e.target.src =
                                    //     "/assets/imgs/notfound.png";
                                    // }}
                                  />
                                </Link>
                              </div>

                              <div class="card-body text-left pb-2 p-0">
                                {/* <button
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                  type="button"
                                  class="btn btn-outline-success  form-control btncccc"
                                >
                                  Share
                                 
                                </button> */}
                                <p class="abg ml-5">{data?.brandId?.name}</p>
                                <br />
                                <Link
                                  to={"/product-details/" + data?.pro_sulg}
                                  class="card-text text-secondary ml-1 menu m-0"
                                >
                                  {/* {data?.title} */}

                                  <TruncateText
                                    text={data?.title}
                                    maxLength={23}
                                  />
                                </Link>
                                <br />
                                {/* <Link
                                  class="abg ml-1"
                                  href={
                                    "/category-product/" +
                                    data?.categoryId?.cate_sulg
                                  }
                                >
                                  {" "}
                                  {data?.categoryId?.name}
                                </Link> */}

                                <br />
                                {value && (
                                  <p class="p-0 m-0 act ml-1">
                                    &nbsp;{data?.afterdiscountprice}
                                    <i class="fa fa-podcast"></i>
                                  </p>
                                )}
                                {value && (
                                  <span class="act1 float-right">
                                    ₹ {data?.price}&nbsp;
                                  </span>
                                )}
                              </div>
                              {/* <ul class="social">
                          <li>
                            <a href="" data-tip="Quick View">
                              <i class="fa fa-eye"></i>
                            </a>
                          </li>
                          <li>
                            <a href="" data-tip="Wishlist">
                              <i class="fa fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a href="" data-tip="add to cart">
                              <i class="fa fa-shopping-cart"></i>
                            </a>
                          </li>
                          <li>
                            <a href="" data-tip="Compare">
                              <i class="fa fa-random"></i>
                            </a>
                          </li>
                        </ul> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {/* <!--product grid--> */}
              <div className="pagination-area mt-20 mb-20">
                {commentsData?.length > 0 && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    className="dataTables_paginate paging_simple_numbers category"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-1-5 primary-sidebar sticky-sidebar">
              <div class="col-12 mobile_hide">
                <details
                  open
                  class="sort"
                  data-aos="fade-top"
                  data-aos-delay={"10"}
                >
                  <input
                    class="box2"
                    type="checkbox"
                    id="vehicle1"
                    // name="vehicle1"
                    value="Low to High"
                    checked={selectedItems.includes("Low to High")}
                    onChange={() => handleCheckboxChange("Low to High")}
                  />
                  <label for="vehicle1">
                    <span class="box3"> Price : Low to High</span>
                  </label>
                  <br />

                  <input
                    class="box2"
                    type="checkbox"
                    id="High to Low"
                    name="High to Low"
                    value="High to Low"
                    checked={selectedItems.includes("High to Low")}
                    onChange={() => handleCheckboxChange("High to Low")}
                  />
                  <label for="High to Low">
                    <span class="box3"> Price : High to Low</span>
                  </label>

                  <summary style={{ fontSize: "14px" }}>Sort</summary>
                </details>

                <br />
                <details
                  open
                  class="sort"
                  data-aos="fade-top"
                  data-aos-delay={"150"}
                >
                  {all_sub_sub_categ_List_fil?.docs?.map((data, i) => {
                    return (
                      <div key={i}>
                        <input
                          class="box2"
                          type="checkbox"
                          id={data?._id}
                          name={data?._id}
                          // value="Bike"
                          value={data?._id}
                          checked={selectedItemscate.includes(data?._id)}
                          onChange={() => handleCheckboxChangecate(data?._id)}
                        />
                        <label for={data?._id}>
                          <span class="box3">{data?.name}</span>
                        </label>
                      </div>
                    );
                  })}

                  <summary> Category</summary>
                </details>

                <br />
                <details
                  open
                  class="sort"
                  data-aos="fade-top"
                  data-aos-delay={"300"}
                >
                  {/* {all_sub_sub_categ_List_fil?.docs?.map((data, i) => {
                    return (
                      <div key={i}>
                        <input
                          class="box2"
                          type="checkbox"
                          id={data?._id}
                          name={data?._id}
                          // value="Bike"
                          value={data?._id}
                          checked={selectedItemscate.includes(data?._id)}
                          onChange={() => handleCheckboxChangecate(data?._id)}
                        />
                        <label for={data?._id}>
                          <span class="box3">{data?.name}</span>
                        </label>
                      </div>
                    );
                  })} */}
                  {generateOptions5()}
                  <summary> Prices</summary>
                </details>
              </div>

              {/* <div className="sidebar-widget widget-category-2 mb-30">
                <h5 className="section-title style-1 mb-30">Category</h5>
                <ul>
                  {all_sub_sub_categ_List_fil?.docs?.map((data, i) => {
                    return (
                      <li
                        key={i}
                        className={data?._id == activeCate ? "activeCate" : ""}
                      >
                        <Link
                          //   onClick={(e) => {
                          //     hendletoFilterpro(data?._id);
                          //   }}
                          href={"/category-product/" + data?.cate_sulg}
                          //   onClick={() => {
                          //     navigate("/category-product/" + data?.cate_sulg);
                          //   }}
                        >
                          {" "}
                          <img src={URL.API_BASE_URL + data?.image} alt="" />
                          {data?.name}
                        </Link>
                      
                      </li>
                    );
                  })}
                </ul>
                {
                  //
                  all_sub_sub_categ_List_fil?.totalPages >
                    all_sub_sub_categ_List_fil?.page && (
                    <a
                      // href={"tel:" + get_seller_details?.whatsappNumber}
                      className="btn btn-xs mt-3"
                      onClick={() => {
                        hendletoPagination();
                      }}
                    >
                      Load More
                    </a>
                  )
                }
              </div> */}
            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
      <FooterMain />
    </div>
  );
}

// export async function getServerSideProps({ params }) {
//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   var raw = JSON.stringify({
//     id: params.id,
//   });

//   var requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };

//   const res = await fetch(
//     URL.API_BASE_URL + `${URL.getsellerProductListPublicebymaincategory2}`,
//     requestOptions
//   );
//   const events = await res.json();

//   const postData = events?.data;

//   if (!events) {
//     return { notFound: true };
//   }

//   return {
//     props: {
//       post: postData,
//       // postList: postDataList,
//     },
//   };
// }

export default MainCategory;
