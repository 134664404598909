import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { useNavigate } from "react-router-dom";
import { getUserDetail } from "../../Utils2/Auth/Token";
function CompleteKyc() {
  const get_user_profile = useSelector((state) =>
    state?.allUserRegister?.get_user_profile
      ? state?.allUserRegister?.get_user_profile
      : {}
  );

  const [images, setImages] = React.useState([]);
  const [userDetailsdata, setuserDetailsdata] = React.useState({});

  useEffect(() => {
    getdddd();
    return () => {};
  }, []);

  const getdddd = async () => {
    const datata = await getUserDetail();
    setuserDetailsdata(datata);
  };
  console.log(userDetailsdata);
  const maxNumber = 1;
  const dispatch = useDispatch();
  const router = useNavigate();
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [images1, setImages1] = React.useState([]);

  const onChange1 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages1(imageList);
  };
  const [images2, setImages2] = React.useState([]);

  const onChange2 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages2(imageList);
  };
  const [imagesPro, setImagesPro] = React.useState([]);

  const onChangePro = (imageList, addUpdateIndex) => {
    // data for submit

    setImagesPro(imageList);
  };
  const [images3, setImages3] = React.useState([]);

  const onChange3 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages3(imageList);
  };

  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const [userKycEdit, setUserKycEdit] = useState({});
  const hendleToprofileEdit1 = (e) => {
    const { name, value } = e?.target;

    setUserKycEdit({ ...userKycEdit, [name]: value });
  };

  function validatePanCard(panNumber) {
    // Regular expression to match PAN card pattern
    var upperPanNumber = panNumber.toUpperCase();
    var panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    // Check if the provided panNumber matches the pattern
    return panRegex.test(upperPanNumber);
  }
  function validateAadhar(aadhar) {
    // Aadhar number format: 12 digits
    var aadharRegex = /^\d{12}$/;

    if (aadharRegex.test(aadhar)) {
      return true; // Valid Aadhar number
    } else {
      return false; // Invalid Aadhar number
    }
  }

  async function validateIFSC(ifsc) {
    const apiUrl = `https://ifsc.razorpay.com/${ifsc}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (response.ok) {
        // Check if the bank returned from API matches the expected bank
        // if (data.BANK.toLowerCase() == userKycEdit?.bank_name) {
        //   return true; // IFSC is valid and matches the expected bank
        // } else {
        //   return false; // IFSC is valid but does not match the expected bank
        // }
        return true;
      } else {
        return false; // Error in API response
      }
    } catch (error) {
      console.error("Error:", error);
      return false; // Error during API call
    }
  }
  const hendleToupdate2 = async () => {
    // edituserProfileByuser
    console.log("userKycEdit?.pan_card");
    console.log(userKycEdit?.pan_card);
    if (!validatePanCard(userKycEdit?.pan_card)) {
      toast.error("invalid pancard");
      return;
    }

    if (!validateAadhar(userKycEdit?.aadhar_card)) {
      // console.log("Valid Aadhar number.");
      toast.error("Invalid Aadhar number.");
      return;
    }

    if (!userKycEdit?.ifsc) {
      toast.error("please enter ifsc code ");
      return;
    }
    const resp = await validateIFSC(userKycEdit?.ifsc);
    if (!resp) {
      toast.error("invalid ifsc code and bank name");
      return;
    }
    if (!userKycEdit?.bank_name) {
      toast.error("please enter bank name ");
      return;
    }
    if (!userKycEdit?.acc_no) {
      toast.error("please enter account number");
      return;
    }

    if (images?.length == 0) {
      toast.error("please select bank passbook");
      return;
    }
    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (images1?.length == 0) {
      // roledata?.role_name

      toast.error("please select aadhar front Image");
      return;
      // data.append("aadharfrontImage", images1[0]?.file);
    }
    if (images2?.length == 0) {
      // roledata?.role_name
      toast.error("please select Aadhar Back Image");
      return;
      // data.append("aadharbacktImage", images2[0]?.file);
    }
    if (images3?.length == 0) {
      // roledata?.role_name
      toast.error("please select pan card Image");
      return;
      // data.append("pancardImage", images3[0]?.file);
    }

    const data = new FormData();
    data.append("pan_card", userKycEdit?.pan_card);
    data.append("aadhar_card", userKycEdit?.aadhar_card);
    data.append("branch_name", userKycEdit?.branch_name);
    data.append("bank_name", userKycEdit?.bank_name);
    data.append("acc_no", userKycEdit?.acc_no);
    data.append("ifsc", userKycEdit?.ifsc);
    data.append("_id", userKycEdit?._id);
    if (images[0]?.file) {
      // roledata?.role_name
      data.append("bankpassbook", images[0]?.file);
    }
    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (images1[0]?.file) {
      // roledata?.role_name
      data.append("aadharfrontImage", images1[0]?.file);
    }
    if (images2[0]?.file) {
      // roledata?.role_name
      data.append("aadharbacktImage", images2[0]?.file);
    }
    if (images3[0]?.file) {
      // roledata?.role_name
      data.append("pancardImage", images3[0]?.file);
    }

    await dispatch(allapiAction.edituserKycByUser(data));
    dispatch(allapiAction.getprofile({}));
    router("/");
    // setShow2(false);
  };

  const rendomColor = [
    "#f6efff",
    "#e4efec",
    "#e9edf9",
    "#6c757d",
    "#c0f3f4",
    "#ecf8d7",
    "#fae8db",
    "#fcd3d1",
    "#d1c6e5",
    "#c0e0f6",
    "#f6f6f8",
    "#e6effd",
    "#fff5f2",
  ];

  function getRandomColor(colors) {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

const randomColor = getRandomColor(rendomColor);
console.log(randomColor);
  return (
    <div>
      <HeaderMain />
      <div className="bg-coverwel" style={{background:randomColor}}>
        <div className="bg-coverwel1">
          <Container className="welcome-messagewel">
            <Row className="justify-content-center align-items-center text-center">
              <Col md={8}>
                <h5>
                  CONGRATULATIONS DEAR {userDetailsdata?.fullName} YOUR ID IS{" "}
                  {userDetailsdata?.referId} SUCCESSFULLY DONE...WE WISH ALL THE
                  BEST FOR YOUR BRIGHT FUTURE WITH RESOLVED IMPROVE LIFE INDIA
                  MARKETING PRIVATE LIMITED FAMILY.
                </h5>
                <h6>
                  YOUR USERNAME: {userDetailsdata?.referId} AND PASSWORD:{" "}
                  {userDetailsdata?.passwordPlan}
                </h6>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <div className="card mx-auto ">
              <div className="kycfrom">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}
                <h4>Bank Details</h4>
                <div className="row">
                  <div className="mb-3 col-md-4">
                    <label for="bank_name" className="form-label">
                      Bank Name
                    </label>
                    <input
                      name="bank_name"
                      className="form-control"
                      placeholder="Enter bank_name"
                      type="text"
                      value={userKycEdit?.bank_name}
                      onChange={(e) => {
                        hendleToprofileEdit1(e);
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label for="title" className="form-label">
                      IFSC
                    </label>
                    <input
                      name="ifsc"
                      className="form-control"
                      placeholder="Enter ifsc"
                      type="text"
                      value={userKycEdit?.ifsc}
                      onChange={(e) => {
                        hendleToprofileEdit1(e);
                      }}
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <label for="title" className="form-label">
                      Branch Name
                    </label>
                    <input
                      name="branch_name"
                      className="form-control"
                      placeholder="Enter branch_name"
                      type="text"
                      value={userKycEdit?.branch_name}
                      onChange={(e) => {
                        hendleToprofileEdit1(e);
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label for="acc_no" className="form-label">
                      Account No.
                    </label>
                    <input
                      name="acc_no"
                      className="form-control"
                      placeholder="Enter Account No."
                      type="text"
                      value={userKycEdit?.acc_no}
                      onChange={(e) => {
                        hendleToprofileEdit1(e);
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label for="product_name" className="form-label">
                      Bank Passbook
                    </label>
                    <ImageUploading
                      // multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["png", "jpeg", "jpg", "webp"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            className="btn btn-primary btn-sm rounded"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click And Select
                          </button>
                          &nbsp;
                          {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image["data_url"]} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-primary btn-sm rounded mb-2"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
                <hr />
                <h4>Document</h4>
                <div className="row">
                  <div className="mb-3 col-md-4">
                    <label for="title" className="form-label">
                      Aadhar Card No.
                    </label>
                    <input
                      name="aadhar_card"
                      className="form-control"
                      placeholder="Enter aadhar card"
                      type="text"
                      value={userKycEdit?.aadhar_card}
                      onChange={(e) => {
                        hendleToprofileEdit1(e);
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label for="product_name" className="form-label">
                      Addhar Front Photo
                    </label>
                    <ImageUploading
                      // multiple
                      value={images1}
                      onChange={onChange1}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["png", "jpeg", "jpg", "webp"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            className="btn btn-primary btn-sm rounded"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click And Select
                          </button>
                          &nbsp;
                          {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image["data_url"]} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-primary btn-sm rounded mb-2"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <div className="mb-3 col-md-4">
                    <label for="product_name" className="form-label">
                      Aadhar back Photo
                    </label>
                    <ImageUploading
                      // multiple
                      value={images2}
                      onChange={onChange2}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["png", "jpeg", "jpg", "webp"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            className="btn btn-primary btn-sm rounded"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click And Select
                          </button>
                          &nbsp;
                          {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image["data_url"]} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-primary btn-sm rounded mb-2"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <div className="mb-3 col-md-4">
                    <label for="pan_card" className="form-label">
                      Pan card
                    </label>
                    <input
                      name="pan_card"
                      className="form-control"
                      placeholder="Enter pan_card"
                      type="text"
                      value={userKycEdit?.pan_card}
                      onChange={(e) => {
                        hendleToprofileEdit1(e);
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label for="product_name" className="form-label">
                      Pan Card Photo
                    </label>
                    <ImageUploading
                      // multiple
                      value={images3}
                      onChange={onChange3}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["png", "jpeg", "jpg", "webp"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            className="btn btn-primary btn-sm rounded"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click And Select
                          </button>
                          &nbsp;
                          {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image["data_url"]} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-primary btn-sm rounded mb-2"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>

                {/* <div className="mb-3">
                  <label for="teg" className="form-label">
                    Product Tag
                  </label>
                  <input
                    name="tegs"
                    className="form-control"
                    placeholder="Enter tag"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div> */}
                {/* <button
                  type="button"
                  class="btn transparent"
                  onClick={(e) => hendleToupdate2(e)}
                >
                  Update
                </button> */}
                <Button
                  variant="customwel"
                  size="lg"
                  className="mt-4"
                  onClick={(e) => hendleToupdate2(e)}
                >
                  Update
                </Button>
                {/* <button
                  class="btn transparent"
                  id="sign-in-btn"
                  onClick={() => {
                    setIsActive(false);
                    setotpBox(1);
                  }}
                >
                  Sign up
                </button> */}
              </div>
            </div>
          </Container>
        </div>
      </div>
      <FooterMain />
    </div>
  );
}

export default CompleteKyc;
