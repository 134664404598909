// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Footerr from "../../../component/sidebar/Footerr";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
import moment from "moment";
import DatePicker from "react-datepicker";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
function MyStatment() {
  const dispatch = useDispatch();
  const profile222 = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );

  const profile = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );
  const user_income_date = useSelector((state) =>
    state?.allapi?.user_income_date ? state?.allapi?.user_income_date : {}
  );
  console.log(profile);
  console.log(user_income_date);
  console.log(user_income_date?.amount);

  useEffect(() => {
    dispatch(allapiAction.getprofile({}));

    return () => {};
  }, []);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [startDate2vs, setStartDate2vs] = useState("");
  const [startDate2vse, setStartDate2vse] = useState("");
  //   userstatement

  const hendleToChackReport = () => {

    if (!startDate2vs) {
      toast.error("please select start date");
      return;
    }
    if (!startDate2vse) {
      toast.error("please select end date");
      return;
    }
    // startDate: startDate2vs,
    // endDate: startDate2vse,
    dispatch(
      allapiAction.userstatement({
        startDate: startDate2vs,
        endDate: startDate2vse,
      })
    );
    return () => {};
  };
  return (
    <div>
      {" "}
      <div>
        <Sidebar />

        <main className="main-wrap">
          <HeaderAccount />

          <section className="content-main">
            <div className="content-header">
              <div>
                <h2 className="content-title card-title">MY STATEMENT</h2>
                {/* <p>Whole data about your business here</p> */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div id="profile_content">
                  {" "}
                  <div
                    class="information-title"
                    style={{ marginBottom: "0px" }}
                  >
                    MY STATEMENT
                  </div>
                  <div className="row dtpick mt-3">
                    {/* <div className="col-4 mt-2">
                      <DatePicker
                        selected={startDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        className="form-control"
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="col-4 mt-2">
                      <DatePicker
                        selected={startDate2}
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        onChange={(date) => setStartDate2(date)}
                      />
                    </div> */}

                    <div class="col-sm-4">
                      <div className="input-field">
                        <i className="fas fa-calendar-check-o"></i>
                        <input
                          type="date"
                          required=""
                          name="dob"
                          placeholder="Enter dob"
                          onChange={(e) => {
                            setStartDate2vs(e?.target?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div className="input-field">
                        <i className="fas fa-calendar-check-o"></i>
                        <input
                          type="date"
                          required=""
                          name="dob"
                          placeholder="Enter dob"
                          onChange={(e) => {
                            setStartDate2vse(e?.target?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        onClick={() => {
                          hendleToChackReport();
                        }}
                        className="btn btn-primary font-sm btn-light rounded mt-5"
                      >
                        Submit
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="recent-post"
                        style={{
                          background: "#fff",
                          border: "1px solid #e0e0e0",
                        }}
                      >
                        <div class="media">
                          {/* <a
                            class="pull-left media-link"
                            href="#"
                            style={{ height: "124px" }}
                          >
                            <div
                              class="media-object img-bg"
                              id="blah"
                              // style="background-image: url('https://rilindia.biz/uploads/user_image/default.jpg'); background-size: cover;background-position-x: center; background-position-y: top; width: 100px; height: 124px;"
                            ></div>
                            <form
                              action="https://rilindia.biz/registration/change_picture/2"
                              class=""
                              method="post"
                              id="fff"
                              enctype="multipart/form-data"
                              accept-charset="utf-8"
                            >
                              <input
                                type="hidden"
                                name="csrf_test_name"
                                value="16d6616dc83ab9da6650ca73a6a3d0ee"
                              />
                              <span id="inppic" class="set_image">
                                <label class="" for="imgInp">
                                  <span>
                                    <i
                                      class="fa fa-pencil"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  id="imgInp"
                                  name="img"
                                />
                              </span>
                              <span id="savepic" style={{ display: "none" }}>
                                <span
                                  class="signup_btn"
                                  onclick="abnv('inppic'); change_state('normal');"
                                  data-ing="Saving..."
                                  data-success="Profile Picture Saved Successfully!"
                                  data-unsuccessful="Edit Failed! Try Again!"
                                  data-reload="no"
                                >
                                  <span>
                                    <i
                                      class="fa fa-save"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </span>
                                </span>
                              </span>
                            </form>
                          </a> */}
                          <div
                            class="media-body"
                            style={{ paddingRight: "20px" }}
                          >
                            <table
                              class="table table-condensed"
                              style={{
                                fontSize: "14px",
                                marginBottom: "0px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <b>FCID</b>
                                  </td>
                                  <td>{profile?.referId}</td>
                                  <td>
                                    <b>Total Commission</b>
                                  </td>
                                  <td>
                                    <b>
                                      ₹{" "}
                                      {user_income_date?.amount
                                        ? user_income_date?.amount
                                        : 0}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Name</b>
                                  </td>
                                  <td align="left">{profile?.fullName}</td>
                                  <td>
                                    <b>TDS Deduction</b>
                                  </td>
                                  <td>
                                    <span>
                                      <b>
                                        ₹{" "}
                                        {user_income_date?.tds
                                          ? user_income_date?.tds
                                          : 0}
                                      </b>
                                    </span>{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <b>Rank</b>
                                  </td>
                                  <td style={{ color: "green" }}>
                                    {" "}
                                    <b>
                                      {" "}
                                      {profile?.status == 0
                                        ? "FC"
                                        : profile?.status == 1
                                        ? "FR"
                                        : profile?.status == 2
                                        ? "BR"
                                        : profile?.status == 3
                                        ? "CT"
                                        : profile?.status == 4
                                        ? "MQ"
                                        : "DUKE"}
                                    </b>
                                  </td>
                                  <td>
                                    <b>Other Deduction</b>
                                  </td>
                                  <td>₹ 0.0</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Status</b>
                                  </td>
                                  <td align="left" style={{ color: "green" }}>
                                    <b>{profile?.user_status} </b>
                                  </td>
                                  <td>
                                    {" "}
                                    <b>Total Payable</b>
                                  </td>
                                  <td>
                                    {" "}
                                    <b>
                                      ₹{" "}
                                      {user_income_date?.netAmount
                                        ? user_income_date?.netAmount
                                        : 0}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Sponsor FCID </b>
                                  </td>
                                  <td>{profile?.referByuserId?.referId}</td>
                                  <td>
                                    <b>Pan No</b>
                                  </td>
                                  <td>-----</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Sponsor Name </b>
                                  </td>
                                  <td>{profile?.referByuserId?.fullName}</td>
                                  <td>
                                    <b>Account No</b>
                                  </td>
                                  <td>-----</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-md-12 dashboard-hides"
                      style={{ display: "none" }}
                    >
                      <i class="fa fa-spinner"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <Footerr /> */}
        </main>
      </div>
    </div>
  );
}

export default MyStatment;
