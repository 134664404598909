

// import Api from "@/Redux/common/api";
// import { URL } from "@/Redux/common/url";
// import OrgChart from "d3-org-chart";
import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
// import dynamic from "next/dynamic";
// import DynamicOrgChart from "../../../component/OrgChartComponent";
// import OrgChartComponent from "../../../component/OrgChartTest";
// import OrganizationChartDemo from "../../../component/OrganizationChartDemo";
// import Orgtest from "../../../component/Orgtest";
// import MixedNodeElement from "../../../component/MixedNodeElement";
import Api from "../../Redux/common/api";
import { URL } from "../../Redux/common/url";
import HeaderAccount from "../Layout/HeaderAccount";
import Sidebar from "../Layout/Sidebar";
import MixedNodeElement from "./MixedNodeElement";
// import OrgChart from "../../../component/OrgChartComponent";
// const OrgChartComponent = dynamic(
//   () => import("../../../component/OrgChartTest"),
//   {
//     ssr: false,
//   }
// );

function Genelogy() {
  const [treeData, setTreeData] = useState(null);
  const [treeData1, setTreeData1] = useState(null);

  console.log(treeData1);

  // const [OrgChart, setOrgChart] = useState(null);
  // console.log(OrgChart);

  // useEffect(() => {
  //   import("../../../component/OrgChartComponent")
  //     .then((module) => {
  //       console.log("sadasd");
  //       console.log("sadasd", module.default);
  //       setOrgChart(module.default);
  //     })
  //     .catch((err) => {
  //       console.error("Failed to load component:", err);
  //     });
  //   console.error("Failed to load component:");
  // }, []);

  useEffect(() => {
    fetchDownlineHierarchy();
  }, []);

  const fetchDownlineHierarchy = async (userId) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await Api.get(`${URL.genelogybyuser}`, config);

      //       createdAt
      // :
      // "2020-03-31T18:30:00.000Z"
      // fullName
      // :
      // "Chitranjan tomar Resolved Improve Life"
      // referBy
      // :
      // ""
      // referId
      // :
      // "RIL00001"
      // status
      // :
      // "4"
      // _id
      // :
      // "6567c5ba5532b0536f80174a"

      const margeList = [
        {
          _id: response?.data?._id,
          status: response?.data?.status,
          referId: response?.data?.referId,
          referBy: response?.data?.referBy,
          fullName: response?.data?.fullName,
          createdAt: response?.data?.createdAt,
        },
        ...response?.data?.children,
      ];
      const margeList2 =
        margeList &&
        margeList?.map((data) => {
          const finaldata = data;
          const status = data?.status;
          const statusdata =
            status == 0
              ? "/assets/imgs/fc.png"
              : status == 1
              ? "/assets/imgs/fr.png"
              : status == 2
              ? "/assets/imgs/br.png"
              : status == 3
              ? "/assets/imgs/ct.png"
              : status == 4
              ? "/assets/imgs/mq.png"
              : status == 5
              ? "/assets/imgs/duke.png"
              : "/assets/imgs/duke.png";
          finaldata.id = data?.referId;
          finaldata.pid = data?.referBy;
          finaldata.img = statusdata;
          finaldata.tags = ["Management"];

          return finaldata;
        });
      // tags: ["Management"]
      // const response = await axios.get(`/api/users/${userId}/downline`);
      margeList2.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setTreeData(response.data);
      setTreeData1(margeList2);
    } catch (error) {
      console.error("Error fetching downline hierarchy:", error);
    }
  };

  const customPathFunc = ({ source, target }) => {
    const stepY = (target.y - source.y) / 2;

    return `M${source.y},${source.x}
            H${source.y + stepY}
            V${target.x - stepY}
            H${target.y}`;
  };

  const CustomNodeComponent = ({ nodeData }) => {
    const { name, attributes } = nodeData;
    const { email, imageUrl } = attributes;

    

    return (
      <div style={{ textAlign: "center", width: "150px", height: "100px" }}>
        <img
          src={imageUrl}
          alt={name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        <div>{name}</div>
        <div>{email}</div>
      </div>
    );
  };

  const data = [
    {
      id: 1,
      firstName: "Terry",
      lastName: "Medhurst",
      maidenName: "Smitham",
      age: 50,
      gender: "male",
      referBy: "0",
    },
    {
      id: 2,
      firstName: "Sheldon",
      lastName: "Quigley",
      maidenName: "Cole",
      age: 28,
      gender: "male",
      referBy: "1",
    },
    {
      id: 3,
      firstName: "Terrill",
      lastName: "Hills",
      maidenName: "Hoeger",
      age: 38,
      gender: "male",
      referBy: "1",
    },
    {
      id: 4,
      firstName: "Miles",
      lastName: "Cummerata",
      maidenName: "Maggio",
      age: 49,
      gender: "male",
      referBy: "2",
    },
    {
      id: 5,
      firstName: "Mavis",
      lastName: "Schultz",
      maidenName: "Yundt",
      age: 38,
      gender: "male",
      referBy: "2",
    },
    {
      id: 6,
      firstName: "Alison",
      lastName: "Reichert",
      maidenName: "Franecki",
      age: 21,
      gender: "female",
      referBy: "4",
    },
    {
      id: 7,
      firstName: "Oleta",
      lastName: "Abbott",
      maidenName: "Wyman",
      age: 31,
      gender: "female",
      referBy: "3",
    },
    {
      id: 8,
      firstName: "Ewell",
      lastName: "Mueller",
      maidenName: "Durgan",
      age: 29,
      gender: "male",
      referBy: "4",
    },
    {
      id: 9,
      firstName: "Demetrius",
      lastName: "Corkery",
      maidenName: "Gleason",
      age: 22,
      gender: "male",
      referBy: "6",
    },
    {
      id: 10,
      firstName: "Eleanora",
      lastName: "Price",
      maidenName: "Cummings",
      age: 37,
      gender: "female",
      referBy: "8",
    },
    {
      id: 11,
      firstName: "Marcel",
      lastName: "Jones",
      maidenName: "Smith",
      age: 39,
      gender: "male",
      referBy: "9",
    },
    {
      id: 12,
      firstName: "Assunta",
      lastName: "Rath",
      maidenName: "Heller",
      age: 42,
      gender: "female",
      referBy: "7",
    },
    {
      id: 13,
      firstName: "Trace",
      lastName: "Douglas",
      maidenName: "Lemke",
      age: 26,
      gender: "male",
      referBy: "12",
    },
    {
      id: 14,
      firstName: "Enoch",
      lastName: "Lynch",
      maidenName: "Heidenreich",
      age: 21,
      gender: "male",
      referBy: "11",
    },
  ];

  const hierarchy = {
    name: "Root", // You can set any root name you like
    children: [],
  };

  const idToNode = {};
  data.forEach((item) => {
    idToNode[item.id] = {
      name: `${item.firstName} ${item.lastName}`,
      attributes: { age: item.age, gender: item.gender },
      parent: item.referBy === "0" ? hierarchy : idToNode[item.referBy],
      children: [],
    };

    if (item.referBy !== "0") {
      idToNode[item.referBy].children.push(idToNode[item.id]);
    }
  });

  const treeDatas = hierarchy;

  const generateTreeData = (data, parentId) => {
    // console.log(data);
    const children = data.filter((item) => item.referByuserId == parentId);
    children.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    return children.map((child) => {
      const status = child.status;
      // console.log(status);/
      const statusdata =
        status == 0
          ? "fc"
          : status == 1
          ? "fr"
          : status == 2
          ? "br"
          : status == 3
          ? "ct"
          : status == 4
          ? "mq"
          : status == 5
          ? "duke"
          : "";
      // console.log(statusdata);
      const statusdata2 =
        status == 0
          ? "/assets/imgs/fc.png"
          : status == 1
          ? "/assets/imgs/fr.png"
          : status == 2
          ? "/assets/imgs/br.png"
          : status == 3
          ? "/assets/imgs/ct.png"
          : status == 4
          ? "/assets/imgs/mq.png"
          : status == 5
          ? "/assets/imgs/duke.png"
          : "/assets/imgs/duke.png";
      return {
        name: `${child.fullName}`,
        img: statusdata2,
        attributes: {
          // id: child.id,
          referId: child.referId,
          // upline: child.referBy,
          // createdAt: child.createdAt,
          // rank: statusdata,
        },
        children: generateTreeData(data, child._id),
      };
    });
  };
  const generateTreeData2 = (data, parentId) => {
    // console.log(data);
    const children = data.filter((item) => item.referByuserId == parentId);
    children.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    return children.map((child) => {
      const status = child.status;
      // console.log(status);/
      const statusdata =
        status == 0
          ? "/assets/imgs/fc.png"
          : status == 1
          ? "/assets/imgs/fr.png"
          : status == 2
          ? "/assets/imgs/br.png"
          : status == 3
          ? "/assets/imgs/ct.png"
          : status == 4
          ? "/assets/imgs/mq.png"
          : status == 5
          ? "/assets/imgs/duke.png"
          : "/assets/imgs/duke.png";
      // console.log(statusdata);
      return {
        name: `${child.fullName}`,
        label: child.referId,
        expanded: false,
        type: "person",
        data: { name: `${child.fullName}`, avatar: "walter.jpg" },
        attributes: {
          // id: child.id,
          // referId: child.referId,
          upline: child.referBy,
          // createdAt: child.createdAt,
          rank: statusdata,
        },
        children: generateTreeData(data, child._id),
      };
    });
  };

  const createTreeData = (user) => {
    const status = treeData.status;
    console.log(status);
    const statusdata =
      status == 0
        ? "fc"
        : status == 1
        ? "fr"
        : status == 2
        ? "br"
        : status == 3
        ? "ct"
        : status == 4
        ? "mq"
        : status == 5
        ? "duke"
        : "";

    const statusdata2 =
      status == 0
        ? "/assets/imgs/fc.png"
        : status == 1
        ? "/assets/imgs/fr.png"
        : status == 2
        ? "/assets/imgs/br.png"
        : status == 3
        ? "/assets/imgs/ct.png"
        : status == 4
        ? "/assets/imgs/mq.png"
        : status == 5
        ? "/assets/imgs/duke.png"
        : "/assets/imgs/duke.png";
    console.log(statusdata);

    const treeData3 = {
      name: treeData?.fullName,
      img: statusdata2,
      attributes: {
        // id: 1,
        referId: treeData.referId,
        // upline: treeData.referBy,
        // createdAt: treeData.createdAt,
        // rank: statusdata,
      },
      children: generateTreeData(
        treeData?.children?.length > 0 ? treeData?.children : [],
        treeData?._id
      ),
    };

    return treeData3;
  };
  const createTreeData2 = (user) => {
    const status = treeData?.status;
    console.log(status);
    const statusdata =
      status == 0
        ? "/assets/imgs/fc.png"
        : status == 1
        ? "/assets/imgs/fr.png"
        : status == 2
        ? "/assets/imgs/br.png"
        : status == 3
        ? "/assets/imgs/ct.png"
        : status == 4
        ? "/assets/imgs/mq.png"
        : status == 5
        ? "/assets/imgs/duke.png"
        : "/assets/imgs/duke.png";
    console.log(statusdata);

    const treeData3 = {
      name: treeData?.fullName,
      label: treeData?.referId,
      expanded: true,
      type: "person",
      data: { name: treeData?.fullName, avatar: statusdata },
      attributes: {
        // id: 1,
        referId: treeData?.referId,
        // upline: treeData.referBy,
        // createdAt: treeData.createdAt,
        rank: statusdata,
      },
      children: generateTreeData2(
        treeData?.children?.length > 0 ? treeData?.children : [],
        treeData?._id
      ),
    };

    return treeData3;
  };

  const datavvv = {
    name: "CEO",
    children: [
      {
        name: "Manager 1",
        children: [{ name: "Employee 1" }, { name: "Employee 2" }],
      },
      {
        name: "Manager 2",
        children: [{ name: "Employee 3" }, { name: "Employee 4" }],
      },
    ],
  };
  console.log(createTreeData2());
  const orgChartData = {
    name: "John Doe",
    imgUrl: "https://example.com/john.jpg",
    title: "CEO",
    children: [
      {
        name: "Team A",
        title: "Sales Team",
        children: [
          {
            name: "Jane Smith",
            imgUrl: "https://example.com/jane.jpg",
            title: "VP of Sales",
          },
          {
            name: "Tom Brown",
            imgUrl: "https://example.com/tom.jpg",
            title: "Sales Manager",
          },
          {
            name: "Alice Johnson",
            imgUrl: "https://example.com/alice.jpg",
            title: "Sales Representative",
          },
        ],
      },
      {
        name: "Team B",
        title: "Marketing Team",
        children: [
          {
            name: "Mike Wilson",
            imgUrl: "https://example.com/mike.jpg",
            title: "VP of Marketing",
          },
          {
            name: "Emily Clark",
            imgUrl: "https://example.com/emily.jpg",
            title: "Marketing Manager",
          },
          {
            name: "Chris Lee",
            imgUrl: "https://example.com/chris.jpg",
            title: "Marketing Specialist",
          },
        ],
      },
    ],
  };
  const orgChartDatavv = {
    name: "John Doe",
    children: [
      { name: "Jane Smith" },
      { name: "Mike Wilson" },
      { name: "Emily Clark" },
    ],
  };
  const renderCustomNodeElement = (rd3tProps, state) => {
    // You can access node data using rd3tProps.nodeData
    const { nodeData, nodeDatum, toggleNode } = rd3tProps;
    // console.log(rd3tProps);
    // console.log(nodeDatum);
    // console.log(nodeData);
    // console.log(state);
    // You can also access state if needed (passed as the second argument)
    // const { someStateValue } = state;

    // Return JSX representing the custom node element
    return (
      <MixedNodeElement
        nodeData={nodeDatum}
        triggerNodeToggle={toggleNode}
        foreignObjectProps={{
          width: 170,
          height: 200,
          x: -85,
          y: -60,
        }}
      />
    );
  };
  return (
    <div>
      <Sidebar />

      <main className="main-wrap ">
        <HeaderAccount/>

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Genelogy</h2>
            </div>
            <div></div>
          </div>
          <div className="card mb-4">
            <header className="card-header1">
              <div className="row align-items-center">
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3"></div>
                <div className="col-md-2 col-6"></div>
                <div className="col-md-2 col-6"></div>
              </div>
            </header>

            {/* <Orgtest /> */}
            {/* <OrganizationChartDemo userdata={createTreeData2()} /> */}
            {/* <img src="/assets/imgs/fc.png"/> */}
            <div
              className="card-body "
              style={{ width: "100%", height: "400px" }}
            >
              {/* {treeData ? (
                <OrgChartComponent
                  data={treeData1}
                  data1={treeData1}
                  // firstNodeData={orgChartData.children[0]}
                />
              ) : (
                <p>Loading...</p>
              )} */}

              {/* old */}

              {/* <Tree data={treeData3} /> */}
              {treeData ? (
                <Tree
                  data={createTreeData(treeData)}
                  orientation="vertical"
                  //   onNodeClick={onNodeClick}
                  pathFunc={"step"}
                  translate={{ x: 450, y: 50 }}
                  renderCustomNodeElement={renderCustomNodeElement}
                  nodeLabelComponent={{
                    render: <CustomNodeComponent />, // Use the custom node component to render the user image and labels
                    foreignObjectWrapper: {
                      x: -75,
                      y: -50,
                    },
                  }}
                  nodeSvgShape={{
                    shape: "none", // Hide the default node shape
                  }}
                  nodeSize={{ x: 230, y: 150 }} // Adjust the node size to accommodate the image and labels
                  collapsible={true}
                  defaultCollapsed
                  initialDepth={1}
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Genelogy;
